@import "@/assets/sass/global.scss";





























































































@import "../../../assets/sass/variables";

h1 {
    margin-bottom : 16px;
}

.button-wrapper {
    margin-bottom : 32px;

    .button {
        display         : inline-flex;
        align-items     : center;
        justify-content : center;
        position        : relative;
        border          : 0;
        border-radius   : 16px;
        outline         : 0;
        height          : 32px;
        padding         : 0px 16px;
        background      : $blue-20;
        font-size       : 13px;
        font-weight     : 500;
        line-height     : 15px;
        transition      : all 0.3s ease-in;
        text-decoration : none;
        color           : black;

        &:not(:last-child) {
            margin-right : 16px;
        }

        &:hover, &.active {
            background : $blue-bright;
            color      : #ffffff;
        }

        &:last-child {
            &:after {
                content  : "";
                position : absolute;
                right    : -16px;
                display  : block;
                width    : 16px;
                height   : 1px;
            }
        }
    }
}

.popup-wrapper {
    position         : absolute;
    top              : 0;
    left             : 0;
    width            : 100%;
    height           : 100%;
    transition       : opacity ease-out 0.2s;
    opacity          : 0;
    background-color : rgba(0, 0, 0, 0.5);
    display          : flex;
    align-items      : center;
    justify-content  : center;
    pointer-events   : none;

    &.active {
        opacity        : 1;
        pointer-events : all;
    }

    .popup {
        background-color : white;
        padding          : 32px;
        border-radius    : 8px;
    }
}

@import "@/assets/sass/global.scss";






















































































































.header {
    width           : 100%;
    display         : flex;
    justify-content : space-between;
}

table {
    width : 100%;

    thead, tbody {
        width : 100%;

        tr {
            cursor : pointer;

            th {
                text-align    : left;
                border-bottom : 8px solid white;
            }

            td {
                border-bottom : 8px solid white;

                &.loading-wrapper {
                    text-align : center;
                }
            }
        }
    }
}

@import "@/assets/sass/global.scss";











































































































































































































































































































































































































































































.filters {
    position        : fixed;
    z-index         : 9;
    top             : 59px;
    left            : 280px;
    display         : flex;
    align-items     : center;
    flex-direction  : row;
    justify-content : flex-start;
    width           : calc(100% - 280px);
    padding         : 8px 16px 8px 48px;
    background      : #ffffff;

    & > * {
        margin-right : 16px;

        &:last-child {
            margin-right : 0;
        }
    }


    @media (max-width : 992px) {
        left  : 0;
        width : 100%;
    }

    .search-button {
        z-index       : 102;
        border        : none;
        border-radius : 4px;
        width         : 120px;
        height        : 50px;
        background    : #003554;
        cursor        : pointer;
        font-family   : ".SF NS", sans-serif;
        font-size     : 16px;
        font-weight   : 600;
        color         : #ffffff;
    }

    .reset-filters {
        cursor : pointer;
    }
}

.graph-area {
    position        : relative;
    left            : -90px;
    display         : flex;
    justify-content : space-between;
    width           : calc(100% + 180px);
    min-height      : 313px;
    background      : #003554;
    color           : #ffffff;

    .title {
        padding-top  : 64px;
        padding-left : 80px;

        .header {
            font-family    : ".SF NS", sans-serif;
            font-size      : 12px;
            font-weight    : bold;
            line-height    : 12px;
            text-transform : uppercase;
            color          : rgba(255, 255, 255, 0.6);
        }

        .hours {
            font-family    : ".SF NS", sans-serif;
            font-size      : 54px;
            font-weight    : 900;
            line-height    : 54px;
            letter-spacing : 0.6px;
            color          : #ffffff;
        }
    }
}

.tabs {
    display        : flex;
    flex-direction : row;

    .tab {
        padding         : 32px 40px;
        font-size       : 18px;
        font-weight     : 600;
        line-height     : 18px;
        text-decoration : none;
        color           : #000000;

        &.router-link-exact-active {
            box-shadow : inset 0 -3px 0 0 #0078bd
        }
    }
}

.content {
    margin-top : 64px;

    .no-results {
        display         : flex;
        justify-content : center;
    }
}

@import "@/assets/sass/global.scss";






























































































@import "../../assets/sass/_variables.scss";
@import "../../assets/sass/media-queries.scss";

h1 {
    @media($phone) {
        margin-bottom : 20px;
    }
}

.search {
    width         : 100%;
    max-width     : 335px;
    margin-bottom : 24px;
    margin-top    : 5px;
    background    : url("/images/icons/icon-search.svg") no-repeat center right 16px;

    @media($phone) {
        width : 100%;
    }

    &::placeholder {
        color     : #40677f;
        font-size : 16px;
    }
}

.contacts-container {
    width     : 100%;
    max-width : 750px;
    display   : flex;

    @media ($phone) {
        width       : calc(100% + 40px);
        margin-left : -20px;
        display     : block;
    }
}

.infinite-loading {
    width     : 100%;
    max-width : 335px;
}


@import "@/assets/sass/global.scss";

























































































































@import "../assets/sass/_variables.scss";
@import "../assets/sass/media-queries.scss";
@import url('https://fonts.googleapis.com/css?family=Rubik:300,300i,400,400i,500,500i,700,700i,900,900i&display=swap');

* {
    margin     : 0;
    box-sizing : border-box;
    padding    : 0;
}

body {
    background-color        : #ffffff;
    color                   : $blue-100;
    -webkit-font-smoothing  : antialiased;
    -moz-osx-font-smoothing : grayscale;
}

h1 {
    margin-bottom : 40px;
    font-size     : 40px;
    font-weight   : 500;
    line-height   : 47px;
    color         : $blue-100;

    @media($phone) {
        font-size   : 30px;
        line-height : 32px;
    }
}

h2 {
    margin-bottom : 16px;
    font-size     : 20px;
    font-weight   : 700;
    line-height   : 24px;
    color         : $blue-100;
}

h3 {
    font-size   : 20px;
    font-weight : 500;
    line-height : 24px;
    color       : $blue-100;
    @media ($phone) {
        font-size : 18px;
    }
}

.btn {
    border        : 0;
    border-radius : 38px;
    outline       : 0;
    height        : 38px;
    padding       : 0px 30px;
    font-size     : 14px;
    font-weight   : 500;
    line-height   : 15px;

    &.small {
        height    : 32px;
        font-size : 13px;
    }

    &.primary {
        background : $blue-bright;
    }

    &.green {
        background : #5bb6a1;
        color      : #ffffff;

        &:disabled {
            background : #dedede;
        }
    }
}

body,
input,
select,
textarea,
button {
    outline     : none;
    font-family : $primary-font, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    line-height : normal;
}

.back-mobile {
    display : none;

    @media($phone) {
        position        : relative;
        display         : inline-block;
        margin-bottom   : 24px;
        width           : 100%;
        padding-left    : 25px;
        font-size       : 13px;
        font-weight     : 500;
        line-height     : 15px;
        text-decoration : none;
        color           : $blue-100;

        &:before {
            content    : '';
            position   : absolute;
            left       : 0px;
            width      : 30px;
            height     : 20px;
            background : url("/images/icons/icon-back.svg") no-repeat;
        }
    }
}

.datepicker-style {

    input {
        border        : 1px solid #d8d8d8;
        border-radius : 4px;
        width         : 100%;
        height        : 48px;
        padding-left  : 15px;
        background    : #ffffff;
        background    : url("/images/icons/icon-calendar.svg") center right 12px no-repeat #ffffff;
        font-size     : 15px;
        color         : $blue-100;
    }
}

label {
    display       : inline-block;
    margin-bottom : 8px;
    width         : 100%;
    font-size     : 16px;
    font-weight   : 500;
    line-height   : 19px;
}

input {
    border        : 1px solid #d8d8d8;
    border-radius : 4px;
    height        : 48px;
    padding-left  : 15px;
    background    : #ffffff;
    font-size     : 15px;
    color         : $blue-100;

    &.full {
        width : 100%;
    }

    &.small {
        width : 67px;
    }
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position : absolute;
    left     : -9999px;
}

[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
    position      : relative;
    display       : inline-block;
    margin-bottom : 20px;
    padding-left  : 35px;
    cursor        : pointer;
    font-size     : 16px;
    font-weight   : 400;
    line-height   : 25px;
    color         : $blue-100;

    &:last-child {
        margin-bottom : 0;
    }
}

[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content       : '';
    position      : absolute;
    top           : 0;
    left          : 0;
    border        : 1px solid #dddddd;
    border-radius : 100%;
    width         : 24px;
    height        : 24px;
    background    : #ffffff;
}

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content            : '';
    position           : absolute;
    top                : 4px;
    left               : 4px;
    border-radius      : 100%;
    width              : 18px;
    height             : 18px;
    background         : $blue-bright;
    -webkit-transition : all 0.2s ease;
    transition         : all 0.2s ease;
}

[type="radio"]:not(:checked) + label:after {
    opacity           : 0;
    -webkit-transform : scale(0);
    transform         : scale(0);
}

[type="radio"]:checked + label:after {
    opacity           : 1;
    -webkit-transform : scale(1);
    transform         : scale(1);
}

//checkboxes
[type="checkbox"]:checked,
[type="checkbox"]:not(:checked) {
    position : absolute;
    left     : -9999px;
}

[type="checkbox"]:checked + label,
[type="checkbox"]:not(:checked) + label {
    position      : relative;
    display       : inline;
    margin-bottom : 20px;
    padding-left  : 35px;
    cursor        : pointer;
    font-size     : 16px;
    font-weight   : 400;
    line-height   : 0;
    color         : $blue-100;

    &:last-child {
        margin-bottom : 0;
    }
}

[type="checkbox"]:checked + label:before,
[type="checkbox"]:not(:checked) + label:before {
    content       : '';
    position      : absolute;
    top           : -2px;
    left          : 0;
    border        : 1px solid #dddddd;
    border-radius : 3px;
    width         : 22px;
    height        : 22px;
    background    : #ffffff;
}

[type="checkbox"]:checked + label:after,
[type="checkbox"]:not(:checked) + label:after {
    content            : '';
    position           : absolute;
    top                : -2px;
    left               : 0px;
    border-radius      : 3px;
    width              : 24px;
    height             : 24px;
    background         : url("/images/icons/icon_checkmark.svg") center center no-repeat $blue-bright;
    -webkit-transition : all 0.2s ease;
    transition         : all 0.2s ease;
}

[type="checkbox"]:not(:checked) + label:after {
    opacity           : 0;
    -webkit-transform : scale(0);
    transform         : scale(0);
}

[type="checkbox"]:checked + label:after {
    opacity           : 1;
    -webkit-transform : scale(1);
    transform         : scale(1);
}

select {
    border             : 1px solid #d8d8d8;
    border-radius      : 4px;
    height             : 48px;
    padding-left       : 15px;
    background         : url("/images/icons/icon-dropdown.svg") no-repeat center right 16px #ffffff;
    font-size          : 15px;
    font-weight        : 500;
    color              : $blue-100;
    -webkit-appearance : none;

    &.small {
        width : 126px;
    }

    &.full {
        width : 100%;
    }
}

button {
    cursor : pointer;
}

ul {
    margin : 0;
}

.green-full-btn {
    display         : flex;
    align-items     : center;
    justify-content : center;
    border          : none;
    border-radius   : 24px;
    width           : 100%;
    height          : 47px;
    padding         : 10px 20px;
    background      : #5bb6a1;
    font-size       : 14px;
    font-weight     : 500;
    line-height     : 100%;
    text-align      : center;
    text-decoration : none;
    color           : #ffffff;
}

.delete-hours {
    display         : flex;
    align-items     : center;
    justify-content : center;
    border          : 1px solid rgba(0, 53, 84, 0.1);;
    border-radius   : 24px;
    width           : 100%;
    height          : 47px;
    padding         : 10px 20px;
    background      : $blue-20;
    font-size       : 14px;
    font-weight     : 400;
    line-height     : 100%;
    text-align      : center;
    text-decoration : none;
    color           : rgba(0, 53, 84, 0.5);

    &:before {
        content      : '';
        display      : inline-block;
        margin-right : 10px;
        width        : 10px;
        height       : 12px;
        background   : url("/images/icons/icon-trash.svg") no-repeat center;
    }
}

#sidebar.sidebar--collapsed ~ .breadcrumbs ~ .content-main {
    margin-left  : 0;
    width        : 100%;
    padding-left : 55px;

    .filters::v-deep {
        left : 70px !important;
    }
}

.content-main {
    margin-left : 280px;
    width       : calc(100% - 280px);
    transition  : all 0.3s ease-in;

    .content-inner {
        padding : 125px 90px 60px 90px;

        @media ($tablet-landscape) {
            padding : 100px 56px 40px 56px;
        }

        @media ($phone) {
            padding : 20px;
        }

        &.without-padding {
            padding-right : 0;
            padding-left  : 0;
        }
    }

    .notification {
        position        : fixed;
        top             : 140px;
        right           : 20px;
        margin-bottom   : 16px;
        box-shadow      : 0px 0px 12px rgba(215, 225, 239, 0.5);
        border          : 1px solid #f2f2f2;
        border-radius   : 16px;
        max-width       : 440px;
        padding         : 16px;
        text-decoration : none;
        color           : inherit;
    }
}

.box-wrap {
    margin-top : 40px;
    width      : 100%;
    max-width  : 790px;

    &.small {
        max-width : 520px;
    }

    @media ($phone) {
        padding-bottom : 0px;
    }
}

.gradient-box {
    position : relative;
    width    : 100%;
    padding  : 25px 20px 25px 20px;

    @media($phone) {
        padding : 16px 0 20px 0;
    }

    &:before {
        content       : '';
        position      : absolute;
        z-index       : -1;
        top           : 0;
        left          : 0;
        right         : 0;
        border-radius : 5px;
        height        : 184px;
        background    : linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%), #f1f5fb;

        @media($phone) {
            left          : -20px;
            right         : -20px;
            border-radius : 0;
            height        : 56px;
            background    : $blue-20;
        }
    }

    h3 {
        margin-bottom : 25px;

        &.bold {
            font-weight : 700;
        }
    }


    &.off-top {
        margin-top : 40px;
        @media ($phone) {
            margin-top : 0;
        }
    }

    .inner-wrap {
        display : flex;

        @media ($phone) {
            flex-direction : column;
            margin-top     : 36px;
        }

        .left {
            width         : 123px;
            min-height    : 1px;
            padding-right : 27px;

            @media ($phone) {
                position      : absolute;
                top           : 8px;
                right         : 0px;
                width         : auto;
                padding-right : 0;
            }

            .submit-hours {
                display         : inline-block;
                border          : 1px solid rgba(0, 53, 84, 0.3);
                border-radius   : 19px;
                box-sizing      : border-box;
                width           : 100%;
                padding         : 10px 20px;
                background      : none;
                cursor          : pointer;
                font-size       : 14px;
                font-weight     : 500;
                line-height     : 17px;
                text-decoration : none;
                color           : rgba(0, 53, 84, 0.5);

                &.active {
                    border : 1px solid $blue-bright;
                    color  : $blue-bright;

                    @media($phone) {
                        border     : 1px solid #5bb6a1;
                        background : #5bb6a1;
                        color      : #ffffff;
                    }
                }
            }
        }

        .right {
            position      : relative;
            box-shadow    : 0px 0px 12px #f1f5fb;
            border        : 1px solid #f2f2f2;
            border-radius : 8px;
            width         : calc(100% - 123px);
            background    : #ffffff;

            @media ($phone) {
                box-shadow : none;
                border     : none;
                width      : 100%;
            }
            @media ($phone) {
                &.mobile-shadow {
                    box-shadow : 0px 0px 12px #f1f5fb;
                    border     : 1px solid #f2f2f2;
                }
            }

            &.naked {
                box-shadow    : none;
                border        : none;
                border-radius : 0;
                background    : none;
            }

            &.padding {
                padding : 32px;

                @media ($phone) {
                    padding : 16px;
                }
            }

            .add-hours {
                display         : flex;
                align-items     : center;
                justify-content : center;
                border          : 1px solid $blue-bright;
                border-radius   : 24px;
                width           : 100%;
                height          : 47px;
                padding         : 10px 20px;
                background      : #ffffff;
                cursor          : pointer;
                font-size       : 14px;
                font-weight     : 500;
                line-height     : 100%;
                text-align      : center;
                text-decoration : none;
                color           : $blue-bright;
                transition      : background-color .3s ease-out;

                &:before {
                    content      : '';
                    display      : inline-block;
                    margin-right : 10px;
                    width        : 20px;
                    height       : 20px;
                    background   : url("/images/icons/icon-add-hours.svg");
                }

                &:hover {
                    background : hsla(192, 87%, 97%, .5);
                }
            }

            p {
                padding     : 32px;
                font-size   : 16px;
                font-weight : 300;
                line-height : 19px;
                color       : $blue-100;
                @media ($phone) {
                    padding : 0 0 32px 0;
                    &.no-padding {
                        padding : 0;
                    }
                }
            }

            .downloads {
                width      : 100%;
                padding    : 0 32px 32px 32px;
                list-style : none;

                @media ($phone) {
                    padding : 0;
                }

                li {
                    display : inline-block;
                    width   : 100%;

                    a {
                        display         : inline-block;
                        border-top      : 1px solid rgba(0, 53, 84, 0.1);
                        width           : 100%;
                        padding         : 22px 0 22px 16px;
                        background      : url("/images/icons/icon-download.svg") no-repeat center right;
                        font-weight     : 500;
                        text-decoration : none;
                        color           : $blue-100;

                        @media ($phone) {
                            padding : 22px 0 22px 0;
                        }

                        .light {
                            font-weight : 300;
                        }
                    }
                }
            }
        }
    }
}

.sidebar--collapsed .breadcrumbs {
    padding-left : 20px;
}

.hamburger {
    display : none;

    @media ($phone) {
        display : none;
    }
}

#sidebar.sidebar--collapsed ~ .breadcrumbs {
    padding-left : 118px;

    @media ($tablet-portrait) {
        padding-left : 114px;
    }
}

.breadcrumbs {
    position                   : fixed;
    z-index                    : 10;
    display                    : flex;
    align-items                : center;
    overflow-x                 : auto;
    width                      : 100%;
    height                     : 60px;
    padding-left               : 360px;
    background                 : #ffffff;
    transition                 : all ease-in 0.3s;
    -webkit-overflow-scrolling : touch;

    &.box-shadow {
        box-shadow : 0px 0px 12px rgba(215, 225, 239, 0.5);
    }

    @media ($tablet-landscape) {
        padding-left : 340px;
    }

    @media($phone) {
        display : none;
    }

    li {
        list-style : none;

        a {
            position        : relative;
            display         : inline-block;
            margin-right    : 35px;
            font-size       : 15px;
            font-weight     : 400;
            line-height     : 24px;
            text-decoration : none;
            color           : $blue-100;

            &:after {
                content         : '';
                position        : absolute;
                top             : 7px;
                right           : -20px;
                width           : 7px;
                height          : 10px;
                background      : url("/images/icons/arrow-right.svg") no-repeat;
                background-size : 100%;
            }
        }

        &:last-child {
            &:not(:first-child) {
                a {
                    margin-right : 16px;
                    font-weight  : 300;
                }
            }

            a {
                &:after {
                    display : none;
                }
            }
        }
    }
}

.profile {
    position    : fixed;
    z-index     : 99;
    top         : 0px;
    right       : 0px;
    overflow    : hidden;
    width       : 224px;
    height      : 123px;
    padding-top : 32px;
    cursor      : pointer;
    transition  : all 0.2s ease-out;

    @media($phone) {
        right       : 0px;
        padding-top : 15px;
    }

    &.hide-mobile {
        @media($phone) {
            display : none;
        }
    }

    &:before {
        content          : '';
        position         : absolute;
        top              : -95px;
        right            : -55px;
        transform        : rotate(25deg);
        border-radius    : 100%;
        width            : 285px;
        height           : 200px;
        background-color : $blue-100;

        @media($phone) {
            height : 175px;
        }
    }

    .profile-wrapper {
        position        : relative;
        z-index         : 99;
        display         : flex;
        align-items     : center;
        justify-content : flex-end;
        padding         : 0 20px;

        img {
            margin-left   : 16px;
            border-radius : 100%;
            width         : 40px;
            height        : 40px;
            object-fit    : cover;

            @media($phone) {
                width  : 36px;
                height : 36px;
            }
        }

        span {
            font-size   : 13px;
            font-weight : 500;
            line-height : 15px;
            color       : #ffffff;
            transition  : all 0.2s ease-out;
        }
    }
}


.loading-default {
    display       : inline-block;
    margin        : 5px 0;
    border        : 1px solid #999999;
    border-radius : 50%;
    width         : 28px;
    height        : 28px;
    font-size     : 28px;
    line-height   : 28px;
    animation     : loading-rotating ease 1.5s infinite;

    &::before {
        content          : "";
        position         : absolute;
        top              : 0;
        left             : 50%;
        display          : block;
        margin-top       : -3px;
        margin-left      : -3px;
        border-radius    : 50%;
        width            : 6px;
        height           : 6px;
        background-color : #999999;
    }
}

@keyframes loading-rotating {
    0% {
        transform : rotate(0);
    }
    100% {
        transform : rotate(360deg);
    }
}


@import "@/assets/sass/global.scss";






















































































































































































































































@import "../../../../assets/sass/variables";
@import "~vue-select/dist/vue-select.css";

.modal-header {
    display         : flex;
    flex-direction  : row;
    justify-content : space-between;

    svg {
        opacity    : 0.2;
        transition : opacity ease-out 0.2s;
        cursor     : pointer;
        height     : 48px;
        width      : 48px;

        &:hover {
            opacity : 1;
        }
    }
}

.upload-autocad-form {
    display        : flex;
    flex-direction : column;

    .tabs {
        margin-bottom : 16px;
        width         : 100%;
        display       : flex;
        gap           : 8px;
    }

    .button {
        display         : inline-flex;
        align-items     : center;
        justify-content : center;
        position        : relative;
        border          : 0;
        border-radius   : 16px;
        outline         : 0;
        height          : 32px;
        padding         : 0px 16px;
        background      : $blue-20;
        font-size       : 13px;
        font-weight     : 500;
        line-height     : 15px;
        transition      : all 0.3s ease-in;
        text-decoration : none;
        color           : black;
        cursor          : pointer;

        &:not(:last-child) {
            margin-right : 16px;
        }

        &:hover, &.active {
            background : $blue-bright;
            color      : #ffffff;
        }

        &:last-child {
            &:after {
                content  : "";
                position : absolute;
                right    : -16px;
                display  : block;
                width    : 16px;
                height   : 1px;
            }
        }
    }

    .tab {
        display        : flex;
        flex-direction : column;

        .drawing-input {
            margin-bottom : 16px;

            label {
                font-weight : bold;
                text-align  : center;
            }

            input {
                width : calc(100% - 32px);
            }
        }

        .buttons {
            display         : flex;
            justify-content : space-between;
        }
    }

    .table-wrapper {
        max-height : 50vh;
        overflow-y : auto;
        overflow-x : hidden;
    }

    .rows {
        min-width : 50vw;

        .row {
            input {
                width : 100%;
            }

            .drawing, .amount {
                min-height : 58px;
            }

            .material {
                min-width : 200px;
            }

            .remove-row {
                visibility    : hidden;
                padding-left  : 8px;
                padding-right : 8px;

                &.shown {
                    visibility : visible;
                }
            }
        }
    }

    button {
        padding-left     : 8px;
        padding-right    : 8px;
        margin-top       : 16px;
        line-height      : 48px;
        background-color : #003554;
        color            : #ffffff;
        border-radius    : 4px;
        font-size        : 20px;
        transition       : all ease-out 0.3s;

        &[disabled] {
            color            : transparentize(#ffffff, 0.2);
            background-color : transparentize(#003554, 0.2);
        }
    }
}

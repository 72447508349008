@import "@/assets/sass/global.scss";






































































































@import "../assets/sass/_variables.scss";
@import "../assets/sass/media-queries.scss";

.hour-row-grid {
    display               : grid;
    grid-template-columns : 1fr 1fr;
    width                 : 100%;
    position              : relative;
    padding               : 14px 0;

    @media($phone) {
        grid-template-columns : 1fr;
        gap                   : 10px;
    }

    &.active {
        border-bottom : 0px;
    }

    .hours {
        font-size   : 14px;
        color       : rgba(0, 53, 84, 0.8);
        font-weight : 400;
        position    : relative;
    }

    .order-ref {
        font-weight   : 300;
        color         : rgba(0, 53, 84, 0.75);
        font-size     : 14px;
        padding-right : 30px;
    }

    .submit-date {
        width         : 100%;
        grid-column   : 1 / span 2;
        color         : #5bb6a1;
        font-weight   : 500;
        font-size     : 12px;
        line-height   : 14px;
        margin-bottom : 8px;

        @media($phone) {
            grid-column : 1;
        }
    }
}

.deleted-alert-box {
    display         : flex;
    justify-content : space-between;
    border-radius   : 15px;
    background      : $blue-20;
    color           : rgba(0, 53, 84, 0.75);
    padding         : 24px 20px;
    font-size       : 14px;
    font-weight     : 400;
    margin-bottom   : 16px;

    @media($phone) {
        flex-direction : column;
        padding        : 10px 16px;
    }

    .undo {
        color           : $blue-bright;
        font-weight     : 500;
        text-decoration : none;

        .timer {
            color : rgba(0, 53, 84, 0.25);
        }
    }
}


@import "@/assets/sass/global.scss";




































































.graph {
    display        : flex;
    flex-direction : row;
    height         : 185px;
    margin-right   : 80px;
    margin-top     : 64px;
    border-bottom  : 1px solid rgba(255, 255, 255, 0.1);

    .column {
        cursor         : pointer;
        position       : relative;
        height         : 100%;
        display        : flex;
        margin-left    : 32px;
        flex-direction : column-reverse;

        &:last-child {
            margin-right : 32px;
        }

        .bar-percentage-wrapper {
            display        : flex;
            flex-direction : column-reverse;
            height         : 100%;

            .percentage {
                font-family     : ".SF NS", sans-serif;
                display         : flex;
                justify-content : center;
                margin-bottom   : 8px;
                font-size       : 14px;
                line-height     : 14px;
                color           : #ffffff;
                font-weight     : 900;
            }


            .bar {
                width                   : 48px;
                border-top-left-radius  : 4px;
                border-top-right-radius : 4px;
            }
        }

        .icon {
            margin-top      : 10px;
            margin-bottom   : 10px;
            display         : flex;
            justify-content : center;

            & > * {
                width  : 24px;
                height : 24px;
            }
        }

        .popover {
            position       : absolute;
            top            : 0;
            left           : -170px;
            height         : 100%;
            width          : 180px;
            opacity        : 0;
            pointer-events : none;
            transition     : all ease-out 0.2s;
            background     : linear-gradient(225deg, #00a4d1 0%, #0078bd 100%);
            box-shadow     : 0 2px 12px 0 rgba(0, 0, 0, 0.6);
            border-radius  : 8px;
            display        : flex;
            flex-direction : column;
            font-family    : ".SF NS", sans-serif;

            .title {
                padding       : 16px 8px;
                color         : #ffffff;
                font-size     : 14px;
                line-height   : 14px;
                font-weight   : 600;
                border-bottom : 1px solid rgba(255, 255, 255, 0.2);
                margin        : 0 0 24px;
            }

            .content {
                h2 {
                    opacity        : 0.6;
                    color          : #ffffff;
                    font-size      : 12px;
                    font-weight    : 900;
                    line-height    : 12px;
                    margin-bottom  : 8px;
                    text-transform : uppercase;
                    padding        : 0 8px;
                }

                .hours {
                    color       : #ffffff;
                    font-size   : 28px;
                    font-weight : bold;
                    line-height : 22px;
                    padding     : 0 8px;
                }
            }
        }

        &:hover {
            .popover {
                transform : translateX(-20px);
                opacity   : 1;
            }
        }
    }
}

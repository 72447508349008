@import "@/assets/sass/global.scss";















































































@import "../../assets/sass/media-queries";

.project-group {
    margin-bottom      : 32px;
    -webkit-box-shadow : 0px 0px 12px #f1f5fb;
    box-shadow         : 0px 0px 12px #f1f5fb;
    border             : 1px solid #f2f2f2;
    border-radius      : 8px;
    padding            : 24px 24px 16px 24px;
    -webkit-animation  : fadein 0.15s;
    animation          : fadein 0.15s;

    .header {
        display        : flex;
        flex-direction : row;

        & > * {
            text-decoration : none;
            margin-right    : 24px;

            &:last-child {
                margin-right : 0;
            }
        }

        .title {
            color       : #003554;
            font-size   : 18px;
            line-height : 18px;
            font-weight : 500;
        }

        .client {
            font-size   : 16px;
            line-height : 18px;
            font-weight : 400;
            color       : rgba(0, 0, 0, 0.4);
        }

        .project-number {
            font-size   : 16px;
            line-height : 18px;
            font-weight : 400;
            color       : rgba(0, 0, 0, 0.4);
        }
    }

    .body {
        table-layout    : fixed;
        margin-top      : 24px;
        border-collapse : collapse;
        width           : 100%;

        thead {
            border-bottom : 1px solid rgba(0, 0, 0, 0.1);

            tr {
                th {
                    font-size      : 14px;
                    line-height    : 18px;
                    vertical-align : middle;
                    color          : rgba(0, 0, 0, 0.4);
                    padding        : 12px 4px;
                    font-weight    : 400;
                    text-align     : left;

                    &.date {
                        width : 30%;
                    }

                    &.employee-name {
                        width : 160px;
                    }

                    &.client-project-number {
                        width         : 180px;
                    }

                    &.rewardable-hours {
                        width : 300px;
                    }

                    &.cost-state-hours {
                        width : 340px;
                    }

                    &.actions {
                        width : 100px;
                    }
                }
            }
        }

        tbody {
        }
    }
}

@import "@/assets/sass/global.scss";


























































































































































































































































































































































































































































































































































































































































































































.infinite-status-prompt {
    display         : flex;
    justify-content : center;
    padding-bottom  : 32px;
    margin-top      : 32px;
    margin-left     : 48px;
    color           : rgba(0, 0, 0, 1);
}

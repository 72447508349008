@import "@/assets/sass/global.scss";


















































@import "../../assets/sass/_variables.scss";

div {
    font-family   : $primary-font;
    font-weight   : 300;
    color         : #003554;
    line-height   : 21px;
    font-size     : 18px;
    margin-bottom : 1em;

    &:last-child {
        margin-bottom : 0;
    }

    &.email {
        font-weight : 600;
    }
}

button {
    margin-top : 1em;
}

@import "@/assets/sass/global.scss";




































































































































































@import "../assets/sass/_variables.scss";
@import "../assets/sass/media-queries.scss";


.submit-button-wrap {
    display         : flex;
    justify-content : space-between;
    align-items     : center;
    padding         : 24px 40px;
    width           : 100%;
    margin-top      : -32px;

    a {
        color           : $blue-bright;
        text-decoration : none;
        font-weight     : 500;
        font-size       : 14px;
        cursor          : pointer;
    }
}

.project-box-main {
    width         : 100%;
    margin-bottom : 40px;
    border        : 1px solid #f2f2f2;
    box-shadow    : 0px 0px 12px #f1f5fb;
    border-radius : 8px;
    background    : #ffffff;

    &:last-child {
        margin-bottom : 0px;
    }

    .submitted-alert {
        padding         : 24px;
        min-height      : 200px;
        width           : 100%;
        display         : flex;
        align-items     : center;
        justify-content : center;
        text-align      : center;

        .success {
            font-weight   : 300;
            width         : 100%;
            display       : inline-block;
            font-size     : 18px;
            line-height   : 25px;
            margin-bottom : 16px;
        }

        .undo {
            color       : $blue-bright;
            font-weight : 500;
            width       : 100%;
            display     : inline-block;
            font-size   : 13px;
            line-height : 15px;
            cursor      : pointer;

            .timer {
                color : rgba(0, 53, 84, 0.25);
            }
        }
    }

    .project-box {
        padding       : 22px 24px;
        width         : 100%;
        display       : inline-block;
        border-bottom : 2px solid #f2f2f2;
        position      : relative;
        color         : inherit;

        @media($phone) {
            padding : 24px 16px;
        }

        &:after {
            content         : '';
            background      : url("/images/icons/arrow-blue.svg");
            background-size : 100%;
            position        : absolute;
            right           : 24px;
            width           : 10px;
            height          : 16px;
            top             : 50%;
            transform       : translateY(-60%);
        }

        .project-ref {
            width         : 100%;
            font-weight   : 300;
            font-size     : 14px;
            line-height   : 17px;
            display       : inline-block;
            margin-bottom : 5px;
        }

        .project-name {
            width       : 100%;
            font-weight : 500;
            font-size   : 16px;
            line-height : 19px;
            display     : inline-block;
        }
    }
}

.hour-list {
    padding : 24px 40px;
    width   : 100%;

    @media($phone) {
        padding : 16px;
    }

    .add-wrap {
        width   : 100%;
        display : inline-block;
        @media($phone) {
            margin-top : 16px;
        }
    }

    .hour-row-container {
        width         : 100%;
        display       : inline-block;
        position      : relative;
        border-bottom : 1px solid #f2f2f2;

        &:last-child {
            border-bottom : 0px;
            margin-bottom : 12px;
        }

        &.active {
            border-bottom : 0px;
        }
    }
}

.alert-container {
    width : 100%;

    .danger {
        width            : 100%;
        background-color : #ffe6e6;
        padding          : 20px 5px;
        border           : 1.2px solid #de0000;
        border-radius    : 5px;
        text-align       : center;

        span {
            color        : #de0000;
            font-weight  : 500;
            font-size    : 13px;
            position     : relative;
            padding-left : 30px;

            &:before {
                content    : '';
                background : url("/images/icons/icon-warning.svg") no-repeat;
                position   : absolute;
                width      : 24px;
                height     : 24px;
                left       : 0;
            }
        }
    }
}

@import "@/assets/sass/global.scss";




























.height {
    height : 81px;
}

.hidden {
    overflow : hidden;
}

.tabSingle {
    opacity    : 0;
    transition : all 0.3s;
    visibility : hidden;
    @media (min-width : 992px) {
        transform : translateX(-50px);
    }
}

.show {
    opacity    : 1;
    visibility : visible;
    @media (min-width : 992px) {
        transform : translateX(0);
    }
}

@import "@/assets/sass/global.scss";





































@import "./assets/sass/_variables.scss";
@import "./assets/sass/media-queries.scss";

.update-message {
    position        : fixed;
    bottom          : 0;
    left            : 0;
    width           : 100vw;
    background      : #ffffff;
    z-index         : 999;
    display         : flex;
    justify-content : center;
    font-size       : 16px;
    padding         : 18px 0;
    box-shadow      : 0 -2px 8px rgba(0, 0, 0, 0.2);
    transition      : all ease-out 0.3s;
    cursor          : pointer;
}

a, button, select, .cursor-pointer {
    cursor : pointer;

    &:disabled {
        cursor : not-allowed;
    }
}

.light-blue-hover {
    transition : background-color .3s ease-out;

    &:hover {
        background-color : hsla(192, 87%, 97%, .5);
    }
}

.hidden-overflow {
    @media ($phone) {
        overflow : hidden;
    }
}

body {
    overflow-x : hidden;
}

.message-container {
    top     : 110px;
    z-index : 100;
    @media ($phone) {
        top : 0;
    }
}

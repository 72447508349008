@import "@/assets/sass/global.scss";

















@import "../assets/sass/_variables.scss";
@import "../assets/sass/media-queries.scss";

.button-wrap {
    width                      : calc(100% + 32px);
    display                    : flex;
    overflow-x                 : auto;
    -webkit-overflow-scrolling : touch;
    padding-bottom             : 15px;
    margin-left                : -16px;
    margin-bottom              : 5px;

    a {
        background      : $blue-20;
        border-radius   : 16px;
        height          : 32px;
        border          : 0;
        padding         : 9px 7px 9px 16px;
        font-weight     : 500;
        text-decoration : none;
        color           : $blue-100;
        display         : inline-flex;
        font-size       : 13px;
        line-height     : 15px;
        outline         : 0;
        transition      : background 0.3s ease-in;
        position        : relative;

        &:hover, &.active {
            background : #10b5e0;
            color      : #ffffff;
        }

        &:hover {
            .amount {
                transition : all 0.2s ease-in;

                &:not(.active) {
                    background : #ffffff;
                    color      : $blue-100;
                }
            }
        }

        .amount {
            border-radius   : 100%;
            display         : inline-flex;
            align-items     : center;
            justify-content : center;
            margin-left     : 10px;
            background      : $blue-100;
            width           : 19px;
            height          : 19px;
            color           : #ffffff;
        }

        &.active {
            .amount {
                background : #ffffff;
                color      : $blue-100;
            }
        }

        &.mrgn-right {
            margin-left : 8px;
        }

        &:first-of-type {
            margin-left : 16px;
            display     : inline-flex;
            align-items : center;
        }

        &:last-child {
            &:after {
                content  : "";
                display  : block;
                position : absolute;
                right    : -16px;
                width    : 16px;
                height   : 1px;
            }
        }

        &:not(.amount) {
            padding : 9px 16px;
        }
    }
}

@import "@/assets/sass/global.scss";


























































































































































@import "../../../assets/sass/_variables.scss";
@import "../../../assets/sass/media-queries.scss";

#submit-btn {
    &:disabled {
        background : transparentize(#5bb6a1, 0.4);
    }
}

.submit-btn {
    margin-top      : 12px;
    display         : inline-block;
    border          : 1px solid rgba(0, 53, 84, 0.3);
    border-radius   : 19px;
    box-sizing      : border-box;
    width           : 100%;
    padding         : 10px 20px;
    background      : none;
    cursor          : pointer;
    font-size       : 14px;
    font-weight     : 500;
    line-height     : 17px;
    text-decoration : none;
    color           : rgba(0, 53, 84, 0.5);

    &.active {
        border : 1px solid $blue-bright;
        color  : $blue-bright;

        @media($phone) {
            border     : 1px solid #5bb6a1;
            background : #5bb6a1;
            color      : #ffffff;
        }
    }
}

.loading-wrapper {
    display         : flex;
    justify-content : center;
    margin-bottom   : 24px;
}

.no-commuting-available {
    width         : 100%;
    display       : inline-block;
    text-align    : center;
    font-weight   : 300;
    font-size     : 18px;
    line-height   : 25px;
    margin-bottom : 20px;
}

h1 {
    @media($phone) {
        margin-bottom : 24px;
    }
}

.box-wrap {
    @media($phone) {
        margin-top : 16px;
    }
}

.commuting-item {
    margin-bottom : 20px;
    position      : relative;

    .commuting-inner {
        display        : flex;
        flex-direction : row;
        background     : #ffffff;
        border         : 1px solid #f2f2f2;
        box-sizing     : border-box;
        box-shadow     : 0px 0px 12px #f1f5fb;
        border-radius  : 8px;
        padding        : 22px 24px;
        position       : relative;

        .submit-wrapper {
            margin-top : 8px;
        }

        .data-wrapper {
            display        : flex;
            flex-direction : column;
        }
    }

    span {
        width   : 100%;
        display : inline-block;

        &.commuting-date {
            font-weight   : 300;
            font-size     : 14px;
            line-height   : 17px;
            margin-bottom : 5px;
        }

        &.commuting-distance {
            font-weight : 500;
            font-size   : 16px;
            line-height : 19px;
        }
    }
}

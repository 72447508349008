@import "@/assets/sass/global.scss";












































































@import "../../../assets/sass/_variables.scss";
@import "../../../assets/sass/media-queries.scss";

h1 {
    @media($phone) {
        margin-bottom : 24px;
    }
}

.box-wrap {
    @media($phone) {
        margin-top : 16px;
    }

    &.loading-container {
        display         : flex;
        justify-content : center;
    }
}


@import "@/assets/sass/global.scss";



























































































input[type=checkbox] {
    position : relative;
    left     : unset;
}

.add-manual {
    margin-bottom  : 32px;
    display        : flex;
    flex-direction : column;

    input {
        vertical-align : center;
        line-height    : 48px;

        &:not(:last-child) {
            margin-bottom : 4px;
        }
    }

    button {
        line-height      : 48px;
        background-color : #003554;
        color            : #ffffff;
        border-radius    : 4px;
        font-size        : 20px;
        transition       : all ease-out 0.3s;

        &[disabled] {
            color            : transparentize(#ffffff, 0.2);
            background-color : transparentize(#003554, 0.2);
        }
    }
}

.manuals-list {
    display        : flex;
    flex-direction : column;

    .loading-wrapper {
        align-self : center;
    }
}

@import "@/assets/sass/global.scss";










































































@import "../assets/sass/_variables.scss";
@import "../assets/sass/media-queries.scss";

.add-hours-form {
    width         : 100%;
    background    : $blue-20;
    border-radius : 15px;
    display       : inline-block;
    padding       : 32px;
    position      : relative;

    @media($phone) {
        position      : fixed;
        top           : 0;
        left          : 0;
        right         : 0;
        bottom        : 0;
        z-index       : 99;
        padding       : 16px;
        border-radius : 0;
        overflow-y    : scroll;
        overflow-x    : hidden;
    }

    .mobile-header {
        display : none;
        @media($phone) {
            display        : block;
            width          : 100%;
            padding-bottom : 30px;
            padding-top    : 20px;
            border-bottom  : 1px solid rgba(0, 53, 84, 0.1);
            margin-bottom  : 20px;

            .project-ref {
                width         : 100%;
                font-weight   : 300;
                font-size     : 14px;
                line-height   : 17px;
                display       : inline-block;
                margin-bottom : 5px;
            }

            .project-name {
                width       : 100%;
                font-weight : 500;
                font-size   : 16px;
                line-height : 19px;
                display     : inline-block;
            }

            .date {
                width       : 100%;
                display     : inline-block;
                font-weight : 500;
                font-size   : 32px;
                line-height : 40px;
                color       : $blue-100;
            }
        }
    }

    .form-group {
        width         : 100%;
        margin-bottom : 24px;

        &.flex {
            display     : flex;
            align-items : center;
        }

        .till {
            padding     : 0 30px;
            font-weight : 500;
            @media ($phone) {
                padding : 0 16px;
            }
        }
    }

    .button-wrap {
        display         : flex;
        justify-content : space-between;
        align-items     : center;
        border-top      : 1px solid rgba(0, 53, 84, 0.1);
        padding-top     : 24px;
        width           : 100%;

        a {
            color           : $blue-bright;
            text-decoration : none;
            font-weight     : 500;
            font-size       : 14px;
            cursor          : pointer;
        }
    }
}

@import "@/assets/sass/global.scss";



















































































































@import "../assets/sass/_variables.scss";
@import "../assets/sass/media-queries.scss";

.mobile-title {
    display : none;
    @media($phone) {
        display        : block;
        font-weight    : 500;
        font-size      : 30px;
        line-height    : 35px;
        margin-top     : 30px;
        padding-bottom : 26px;
        margin-bottom  : 20px;
        border-bottom  : 1px solid rgba(0, 53, 84, 0.1);
    }
}

.edit-hours {
    position   : absolute;
    right      : 16px;
    background : #000000;
    top        : 50%;
    transform  : translateY(-50%);
    width      : 15px;
    height     : 16px;
    align-self : center;
    background : url("/images/icons/icon-edit.svg") no-repeat center center;
    cursor     : pointer;
}

.add-hours-form {
    width         : 100%;
    background    : $blue-20;
    border-radius : 15px;
    display       : inline-block;
    padding       : 32px;
    position      : relative;

    @media($phone) {
        position      : fixed;
        top           : 0;
        left          : 0;
        right         : 0;
        bottom        : 0;
        z-index       : 99;
        padding       : 16px;
        border-radius : 0;
        overflow-y    : scroll;
        overflow-x    : hidden;
    }

    .total-km {
        color         : $blue-100;
        font-weight   : 500;
        font-size     : 16px;
        line-height   : 19px;
        width         : 100%;
        display       : inline-block;
        margin-bottom : 25px;
    }

    .form-group {
        width         : 100%;
        margin-bottom : 24px;

        &:last-child {
            margin-bottom : 0;
        }

        &.flex {
            display     : flex;
            align-items : center;
        }

        .km {
            margin-left : 8px;
        }
    }

    .button-wrap {
        display         : flex;
        justify-content : space-between;
        align-items     : center;
        border-top      : 1px solid rgba(0, 53, 84, 0.1);
        padding-top     : 24px;
        margin-top      : 24px;
        width           : 100%;

        a {
            color           : $blue-bright;
            text-decoration : none;
            font-weight     : 500;
            font-size       : 14px;
            cursor          : pointer;
        }
    }
}

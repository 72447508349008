@import "@/assets/sass/global.scss";
















































































@import "../assets/sass/_variables.scss";
@import "../assets/sass/media-queries.scss";

h1 {
    @media($phone) {
        margin-bottom : 24px;
    }
}

@import "@/assets/sass/global.scss";






































































































































@import "../../assets/sass/_variables.scss";

table {
    width : 100%;

    thead, tbody {
        width : 100%;

        tr {
            margin-bottom : 8px;
            cursor        : pointer;

            &.error {
                input {
                    border : 1px solid $statusBlueDark;
                }
            }

            th {
                text-align    : left;
                border-bottom : 8px solid white;

                svg {
                    margin-left : 8px;
                    width       : 1em;
                    height      : 1em;
                    transition  : transform ease-out 0.3s;

                    &.invert {
                        transform : rotateZ(180deg);
                    }
                }

                &:not(:last-child) {
                    padding-right : 24px;
                }
            }

            td {
                border-bottom : 8px solid white;

                &.loading-wrapper {
                    text-align : center;
                }

                &:not(:last-child) {
                    padding-right : 24px;
                }
            }
        }
    }
}

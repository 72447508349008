@import "@/assets/sass/global.scss";



































































































































@import "../assets/sass/_variables.scss";
@import "../assets/sass/media-queries.scss";

a {
    text-decoration : none;
    color           : black;
}

.right-inner-wrap {
    width         : 100%;
    background    : #ffffff;
    border        : 1px solid #f2f2f2;
    box-sizing    : border-box;
    box-shadow    : 0px 0px 12px #f1f5fb;
    border-radius : 8px;
    @media($phone) {
        width         : 100%;
        border-radius : 0;
        height        : 100%;
        top           : 0;
        z-index       : 99;
        left          : 0;
        right         : 0;
        bottom        : 0;

        &.fixed {
            position : fixed;
        }
    }

    .main-personalinfo {
        background      : $blue-20;
        padding         : 30px 20px;
        display         : flex;
        flex-direction  : column;
        align-items     : center;
        justify-content : center;
        min-height      : 235px;

        @media($phone) {
            min-height : auto;
        }

        img {
            border-radius : 100%;
            width         : 100px;
            height        : 100px;
            margin-bottom : 16px;
            object-fit    : cover;
        }

        .name {
            font-weight   : 500;
            font-size     : 30px;
            line-height   : 36px;
            margin-bottom : 4px;
            text-align    : center;
        }

        .company-name {
            font-size     : 13px;
            line-height   : 22px;
            margin-bottom : 8px;
            text-align    : center;
        }

        .function {
            font-weight : 300;
            font-size   : 20px;
            line-height : 24px;
            text-align  : center;
        }
    }

    .personaldetails {
        width   : 100%;
        display : inline-block;

        .detail-row {
            width         : 100%;
            padding       : 16px 20px;
            border-bottom : 1px solid rgba(0, 53, 84, 0.1);

            &.email {
                background : url("/images/icons/icon-enveloppe.svg") no-repeat center right 20px;
            }

            &.phone {
                background : url("/images/icons/icon-phone.svg") no-repeat center right 20px;
            }

            &.place {
                background : url("/images/icons/icon-place.svg") no-repeat center right 20px;
            }

            .label {
                width       : 100%;
                font-weight : 500;
                display     : inline-block;
                font-size   : 16px;
                line-height : 26px;
            }

            .info {
                font-weight : 300;
                width       : calc(100% - 20px);
                display     : inline-block;
                font-size   : 16px;
                line-height : 26px;
            }
        }
    }
}

@import "@/assets/sass/global.scss";





















































































































































































































.app-overlay {
    position       : fixed;
    z-index        : 101;
    top            : 0;
    left           : 0;
    opacity        : 0;
    width          : 100vw;
    height         : 100vh;
    pointer-events : none;
    transition     : all ease-out 0.2s;

    &.active {
        opacity        : 1;
        cursor         : pointer;
        pointer-events : all;
    }
}

#status-filter-main {
    position : relative;
}

.select-box {
    display         : flex;
    align-items     : center;
    justify-content : center;
    border-radius   : 4px;
    width           : fit-content;
    height          : 50px;
    padding-right   : 16px;
    padding-left    : 16px;
    background      : #e4edf2;
    cursor          : pointer;
    font-family     : ".SF NS", sans-serif;
    font-size       : 16px;
    font-weight     : 600;
    line-height     : 16px;
    transition      : all ease-out 0.2s;

    &.active {
        background : #008dc7;
        color      : #ffffff;
    }

    .arrow {
        content      : '';
        opacity      : 0.6;
        margin-left  : 16px;
        border-width : 5px 5px 0 5px;
        border-style : solid;
        border-color : #000000 transparent transparent transparent;
        width        : 0;
        height       : 0;
    }

    .header {
        display        : flex;
        flex-direction : column;

        .subtitle {
            display        : flex;
            flex-direction : row;
            font-size      : 14px;
            color          : transparentize(white, 0.2);

            &::before {
                content        : attr(data-tooltip);
                position       : absolute;
                top            : 52px;
                left           : 0px;
                font-size      : 0.9em;
                padding        : 8px 16px;
                display        : block;
                color          : white;
                background     : transparentize(#003554, 0.1);
                border-radius  : 4px;
                transition     : opacity 0.1s ease-out;
                z-index        : 99;
                text-align     : left;
                opacity        : 0;
                pointer-events : none;
                white-space    : pre-wrap;
                line-height    : 175%;
            }

            &:hover::before {
                opacity : 1;
            }
        }
    }
}

.overlay {
    position       : absolute;
    z-index        : 999;
    top            : 50px;
    left           : 0;
    opacity        : 0;
    overflow-y     : auto;
    box-shadow     : 0 20px 30px -10px rgba(0, 0, 0, 0.5);
    border-radius  : 6px;
    min-width      : 310px;
    max-height     : 400px;
    padding        : 0 16px 16px;
    background     : #ffffff;
    pointer-events : none;
    transition     : all ease-out 0.2s;

    &.active {
        opacity        : 1;
        transform      : translateY(8px);
        pointer-events : all;
    }

    .searchbar-wrapper {
        position       : sticky;
        top            : 0;
        padding-top    : 8px;
        padding-bottom : 8px;
        background     : #ffffff;

        .searchbar {
            border        : none;
            border-radius : 4px;
            width         : 100%;
            height        : 36px;
            padding       : 0 16px;
            background    : #e6e6e6;
            font-family   : ".SF NS", sans-serif;
            font-size     : 16px;
            line-height   : 16px;
        }
    }

    .option {
        margin-top    : 16px;
        border-radius : 4px;
        padding       : 8px;
        background    : #ffffff;
        cursor        : pointer;
        transition    : all ease-out 0.2s;

        &:first-child {
            margin-top : 0;
        }

        &:hover {
            background : rgba(0, 120, 189, 0.1);
        }

        &.active {
            background : #008dc7;
            color      : #ffffff;
        }
    }
}

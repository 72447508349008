@import "@/assets/sass/global.scss";


























































































































































































































@import "../../../assets/sass/_variables.scss";
@import "../../../assets/sass/media-queries.scss";

.back-mobile {
    display : none;

    @media($phone) {
        color           : $blue-100;
        text-decoration : none;
        font-weight     : 500;
        display         : inline-block;
        font-size       : 13px;
        position        : relative;
        width           : 100%;
        line-height     : 15px;
        margin-bottom   : 24px;
        padding-left    : 25px;

        &:before {
            content    : '';
            background : url("/images/icons/icon-back.svg") no-repeat;
            width      : 30px;
            left       : 0px;
            height     : 20px;
            position   : absolute;
        }
    }
}

.client-name {
    font-weight : 300;
    font-size   : 20px;
    line-height : 24px;
    color       : rgba(0, 53, 84, 0.8);
}

.main-orderinfo {
    display               : grid;
    grid-template-columns : 1fr 1fr;
    gap                   : 32px;

    @media ($phone) {
        margin-bottom : 40px;
    }

    @media($tablet-portrait) {
        grid-template-columns : 100%;
        box-shadow            : 0px 0px 4px rgba(0, 0, 0, 0.12);
        border                : 1px solid #f2f2f2;
        gap                   : 0;
    }

    .left {
        position      : relative;
        border        : 1px solid #f2f2f2;
        box-shadow    : 0px 0px 4px rgba(0, 0, 0, 0.12);
        border-radius : 5px;
        min-height    : 200px;
        padding       : 26px 20px;

        @media($tablet-portrait) {
            box-shadow : none;
            border     : 0px;
            padding    : 26px 20px 0px 20px;
        }

        .address-info {
            display        : flex;
            padding-bottom : 26px;
            border-bottom  : 1px solid rgba(0, 53, 84, 0.1);

            &:before {
                content         : '';
                background      : url("/images/icons/icon-maps.svg") no-repeat left center;
                background-size : contain;
                width           : 45px;
                min-width       : 45px;
                height          : 24px;
            }

            a {
                color           : $blue-bright;
                text-decoration : none;
            }
        }

        .date-info {
            display         : flex;
            padding         : 26px 40px 26px 0px;
            align-items     : center;
            border-bottom   : 1px solid rgba(0, 53, 84, 0.1);
            position        : relative;
            color           : inherit;
            text-decoration : none;

            svg {
                background-size : 100%;
                position        : absolute;
                right           : 8px;
                width           : 24px;
                height          : 24px;
                top             : 50%;
                transform       : translateY(-55%);
            }

            &:before {
                content         : '';
                background      : url("/images/icons/icon-time.svg") no-repeat left center;
                background-size : contain;
                width           : 45px;
                height          : 24px;
            }
        }

        .client-info {
            display         : flex;
            margin          : 26px 0 0 0;
            padding         : 0 40px 0 0;
            align-items     : center;
            position        : relative;
            color           : inherit;
            text-decoration : none;
            cursor          : pointer;

            @media($tablet-portrait) {
                padding       : 26px 40px 26px 0;
                border-bottom : 1px solid rgba(0, 53, 84, 0.1);
                margin        : 0;
            }

            .client-col {
                display        : flex;
                flex-direction : column;
            }

            &:before {
                content         : '';
                background      : url("/images/icons/icon-contacts.svg") no-repeat left 3px center;
                background-size : contain;
                width           : 45px;
                height          : 20px;
            }

            &:after {
                content         : '';
                background      : url("/images/icons/arrow-blue.svg") no-repeat;
                background-size : 100%;
                position        : absolute;
                right           : 16px;
                width           : 10px;
                height          : 16px;
                top             : 50%;
                transform       : translateY(-55%);
            }
        }

        .client-popover {
            @media (min-width : 993px) {
                width          : 350px;
                position       : absolute;
                right          : -345px;
                top            : 210px;
                opacity        : 0;
                z-index        : 99;
                transition     : all ease-out 0.2s;
                pointer-events : none;
                box-shadow     : 0 4px 8px rgba(0, 0, 0, 0.12);

                &.active {
                    transform      : translateX(10px);
                    opacity        : 1;
                    pointer-events : all;
                }
            }

            @media ($tablet-portrait) {
                position   : relative;
                top        : 0;
                right      : 0;
                width      : 100%;
                transition : all ease-out 0.2s;
                overflow   : hidden;
            }
        }

        .flex-col {
            display        : flex;
            flex-direction : column;
            line-height    : 22px;

            .row-1 {
                font-weight : 300;
                font-size   : 18px;
            }

            .row-2 {
                font-size : 13px;
            }
        }
    }

    .right {
        @media($tablet-portrait) {
            padding : 26px 20px;
        }

        .vehicles-main {
            width          : 100%;
            border-bottom  : 1px solid rgba(0, 53, 84, 0.1);
            padding-bottom : 20px;

            @media($tablet-portrait) {
                padding-bottom : 26px;
            }

            .vehicles-grid {
                display               : grid;
                grid-template-columns : 24px 130px auto;
                grid-template-areas   : 'icon license name' 'green-card green-card green-card';
                margin-bottom         : 30px;
                align-items           : center;
                grid-gap              : 13px;

                &:last-child {
                    margin-bottom : 0;
                }

                .vehicle-icon {
                    grid-area : icon;
                    height    : 100%;

                    &.truck {
                        background : url("/images/icons/icon-truck.svg") no-repeat center center;
                    }

                    &.trailer {
                        background : url("/images/icons/icon-trailer.svg") no-repeat center center;
                    }
                }

                .vehicle-license {
                    cursor    : pointer;
                    grid-area : license;
                    width     : 100%;

                    .plate {
                        background     : #ffe500;
                        border         : 1px solid #525252;
                        border-radius  : 5px;
                        align-self     : center;
                        padding        : 5px 5px 5px 22px;
                        width          : 100%;
                        text-align     : center;
                        color          : #000000;
                        font-weight    : 500;
                        display        : inline-block;
                        font-size      : 16px;
                        line-height    : 19px;
                        text-transform : uppercase;
                        position       : relative;

                        &:before {
                            content         : '';
                            background      : url("/images/license-nl.svg") center center #0050ec no-repeat;
                            background-size : 8px 17px;
                            width           : 16px;
                            height          : 100%;
                            position        : absolute;
                            left            : 0;
                            top             : 0;
                        }
                    }
                }

                .vehicle-name {
                    grid-area : name;
                }

                .green-card-url {
                    margin-top      : 8px;
                    grid-area       : green-card;
                    text-decoration : none;
                    color           : #00b0de;
                }
            }
        }

        .employees-main {
            display     : flex;
            align-items : center;
            padding-top : 30px;
            flex-wrap   : wrap;

            span {
                margin-right : 5px;
            }

            img {
                margin        : 5px;
                width         : 48px;
                height        : 48px;
                object-fit    : cover;
                border-radius : 100%;
            }
        }
    }
}

.hour-list {
    padding : 24px 40px;
    width   : 100%;

    @media($phone) {
        padding : 0 16px 16px 16px;
    }

    .add-wrap {
        width      : 100%;
        margin-top : 24px;
        display    : inline-block;
        @media($phone) {
            margin-top : 16px;
        }
    }

    .hour-row-container {
        width    : 100%;
        display  : inline-block;
        position : relative;

        .deleted-alert-box {
            display         : flex;
            justify-content : space-between;
            border-radius   : 15px;
            background      : $blue-20;
            color           : rgba(0, 53, 84, 0.75);
            padding         : 24px 20px;
            font-size       : 14px;
            font-weight     : 400;

            .undo {
                color           : $blue-bright;
                font-weight     : 500;
                text-decoration : none;
            }
        }
    }

    .hour-row-grid {
        display               : grid;
        grid-template-columns : 1fr 1fr;
        grid-column-gap       : 20px;
        width                 : 100%;
        position              : relative;
        border-bottom         : 1px solid #f2f2f2;
        padding               : 14px 0;

        @media($phone) {
            grid-template-columns : 1fr;
            gap                   : 10px;
        }

        &.active {
            border-bottom : 0px;
        }

        .submit-date {
            width         : 100%;
            grid-column   : 1 / span 2;
            color         : #5bb6a1;
            font-weight   : 500;
            font-size     : 12px;
            line-height   : 14px;
            margin-bottom : 8px;

            @media($phone) {
                grid-column : 1;
            }
        }

        .hours {
            font-size   : 14px;
            color       : rgba(0, 53, 84, 0.8);
            font-weight : 400;
            position    : relative;
        }

        .order-ref {
            font-weight   : 300;
            color         : rgba(0, 53, 84, 0.75);
            font-size     : 14px;
            padding-right : 30px;
        }

        &:last-child {
            border-bottom : 0px;
        }
    }
}

.tooltip-wrapper {
    position        : relative;
    display         : flex;
    justify-content : center;
    align-items     : center;

    .tooltip {
        position         : absolute;
        bottom           : 100%;
        margin-bottom    : 8px;

        display          : inline-block;
        justify-content  : center;
        align-items      : center;

        border-radius    : 4px;
        background-color : #021e2e;
        box-shadow       : 0 4px 12px -4px rgba(0, 0, 0, 0.5);
        padding          : 8px;

        color            : #ffffff;
        font-size        : 12px;
        font-weight      : bold;
        line-height      : 14px;
        text-align       : center;
        white-space      : nowrap;

        transition       : all ease-in-out 0.1s;
        transform        : translateY(50%) scale(0.6);
        opacity          : 0;
    }

    &:hover {
        .tooltip {
            transform : translateY(0) scale(1);
            opacity   : 1;
        }
    }
}

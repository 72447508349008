@import "@/assets/sass/global.scss";







































































































































































































































































































































































































































































* {
    position : relative;
}

input[type=checkbox] {
    position : relative;
    left     : unset;
}

.content-header {
    padding-left : 48px;
    width        : 100%;

    .project-number {
        font-family : ".SF NS", sans-serif;
        color       : #021e2e;
        font-weight : bold;
        font-size   : 36px;
        line-height : 36px;
    }

    .information {
        color          : #021e2e;
        display        : flex;
        flex-direction : row;
        align-items    : center;
        margin-top     : 8px;
        font-size      : 26px;

        .wrapper {
            display        : flex;
            flex-direction : row;
            align-items    : center;
            font-size      : 26px;
        }

        .block {
            background     : linear-gradient(180deg, #0a85cc 0%, #006ead 100%);
            border-radius  : 4px;
            align-self     : center;
            margin         : 8px 0;
            padding        : 6px 10px;
            color          : #ffffff;
            font-family    : ".SF NS", sans-serif;
            font-size      : 12px;
            font-weight    : bold;
            line-height    : 12px;
            letter-spacing : 0.2px;
            text-transform : uppercase;
            cursor         : pointer;
        }
    }
}

@keyframes loading-rotating {
    0% {
        transform : rotate(0);
    }
    100% {
        transform : rotate(360deg);
    }
}

.loading-wrapper {
    width           : 100%;
    height          : 100%;
    display         : flex;
    justify-content : center;
    align-items     : center;

    .loading-default {
        display       : inline-block;
        margin        : 5px 0;
        width         : 28px;
        height        : 28px;
        font-size     : 28px;
        line-height   : 28px;
        border-radius : 50%;
        border        : 1px solid #999999;
        animation     : loading-rotating ease 1.5s infinite;

        &::before {
            content          : "";
            position         : absolute;
            display          : block;
            top              : 0;
            left             : 50%;
            margin-top       : -3px;
            margin-left      : -3px;
            width            : 6px;
            height           : 6px;
            background-color : #999999;
            border-radius    : 50%;
        }
    }
}

.buttons {
    margin-left     : 48px;
    margin-right    : 48px;
    margin-bottom   : 16px;
    display         : flex;
    justify-content : space-between;
    flex-direction  : row;

    .left-buttons {
        display        : flex;
        flex-direction : row;


    }

    .right-buttons {
        display        : flex;
        flex-direction : column;
        align-items    : flex-end;

        .row {
            display        : flex;
            flex-direction : row;
            margin-bottom  : 16px;

            &:last-child {
                margin-bottom : 0;
            }

            select {
                padding-right : 15px;
            }
        }
    }

    .button {
        cursor          : pointer;
        margin-right    : 8px;
        display         : flex;
        padding         : 8px;
        justify-content : center;
        align-items     : center;
        font-family     : ".SF NS", sans-serif;
        font-size       : 18px;
        color           : #ffffff;
        height          : 48px;
        border-radius   : 4px;
        box-shadow      : 0px 1px 0px rgba(0, 0, 0, 0.1);
        transition      : all ease-out 0.3s;

        &:last-child {
            margin-right : 0;
        }
    }

    .select-wrapper {
        select {
            height        : 48px;
            font-size     : 14px;
            border-radius : 4px;
            background    : #ffffff;
            border        : none;
            box-shadow    : inset 0 0 0 1px rgba(0, 0, 0, 0.15);
            margin-right  : 8px;
        }

        .project-dates-unknown {
            margin-right : 16px;
            display      : flex;
            align-items  : center;
        }
    }

    .green-button {
        background : #7ccc55;

        &:hover {
            background : #61b737;
        }

        &.disabled {
            cursor     : not-allowed;
            background : rgba(124, 204, 85, 0.5);
        }
    }

    .dark-button {
        background : #4a4a4a;

        &:hover {
            background : #313131;
        }
    }

    .man-day-state {
        background : #28a0c1;

        &:hover {
            background : #0a85cc;
        }

        &[disabled] {
            cursor     : not-allowed;
            color      : darken(#ffffff, 10%);
            background : darken(#28a0c1, 10%);

            &:hover {
                color      : darken(#ffffff, 10%);
                background : darken(#28a0c1, 10%);
            }
        }
    }
}

.content {
    margin : 48px 48px 64px;

    .project-table {
        width         : calc(100% - 92px);
        margin-right  : 48px;
        margin-top    : 32px;
        margin-bottom : 98px;

        thead {

            tr {
                box-shadow : 0px 1px 0px rgba(0, 0, 0, 0.1);

                th {
                    padding        : 12px 16px;
                    font-family    : ".SF NS", sans-serif;
                    font-weight    : 500;
                    mix-blend-mode : normal;
                    font-size      : 14px;
                    color          : rgba(0, 0, 0, 0.5);
                    text-align     : left;

                    .table-sort {
                        margin-left : 4px;
                        display     : inline-block;
                        width       : 10px;
                        height      : 10px;

                        &::before {
                            top          : -2px;
                            border-width : 0 5px 5px;
                            border-color : transparent transparent #000000;
                            display      : block;
                            position     : absolute;
                            width        : 0;
                            height       : 0;
                            transition   : opacity 0.2s;
                            border-style : solid;
                            content      : '';
                            opacity      : 0.5;
                        }

                        &::after {
                            bottom       : -2px;
                            border-width : 5px 5px 0;
                            border-color : #000000 transparent transparent;
                            display      : block;
                            position     : absolute;
                            width        : 0;
                            height       : 0;
                            transition   : opacity 0.2s;
                            border-style : solid;
                            content      : '';
                            opacity      : 0.5;
                        }
                    }
                }
            }
        }

        tbody {
            tr {
                box-shadow : 0px 1px 0px rgba(0, 0, 0, 0.1);

                td {
                    padding     : 22px 16px;
                    font-family : ".SF NS", sans-serif;
                    font-size   : 14px;
                    line-height : 18px;
                    color       : #000000;

                    &.employee-name {

                    }

                    &.project-number {
                        border        : 1px solid rgba(0, 0, 0, 0.1);
                        border-radius : 4px;
                    }

                    &.date {

                    }

                    &.start-time {

                    }

                    &.end-time {

                    }

                    &.total-hours {

                    }

                    &.hourly-tariff {

                    }

                    &.total-price {

                    }

                    &.status {

                    }
                }

                .time-registration {
                    cursor        : pointer;
                    padding       : 8px 16px;
                    background    : #ebeff5;
                    border-radius : 16px;

                    font-weight   : 500;
                    font-size     : 13px;
                    line-height   : 15px;
                    color         : #003554;
                    transition    : all ease-out 0.2s;

                    &:hover {
                        background : darken(#ebeff5, 5%);
                    }
                }
            }
        }
    }
}

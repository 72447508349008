@import "@/assets/sass/global.scss";
















































































h1 {
    margin-bottom : 4px;
}

h3 {
    font-size : 24px;
}

.risk-wrapper {
    z-index    : 8;
    margin-top : 112px;

    .risk-row {
        display        : flex;
        flex-direction : row;
        margin-bottom  : 16px;

        label {
            line-height : 24px;
        }
    }
}

.content-header {
    z-index         : 9;
    background      : white;
    position        : fixed;
    padding-top     : 100px;
    padding-bottom  : 10px;
    top             : 0;
    display         : flex;
    flex-direction  : row;
    justify-content : space-between;

    .column {
        display        : flex;
        flex-direction : column;
    }
}

.submit-button {
    font-family   : ".SF NS", sans-serif;
    cursor        : pointer;
    height        : 50px;
    width         : 120px;
    background    : #003554;
    color         : #ffffff;
    border-radius : 4px;
    font-size     : 16px;
    font-weight   : 600;
    z-index       : 102;
    border        : none;
    margin-left   : 80px;
}

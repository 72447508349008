@import "@/assets/sass/global.scss";















@import "../../assets/sass/_variables.scss";
@import "../../assets/sass/media-queries.scss";

.login-container {
    display          : flex;
    width            : 100vw;
    height           : 100%;
    min-height       : 100vh;
    background-color : $blue-20;

    @media($phone) {
        flex-direction : column;
    }

    .login-content {
        position : relative;
        z-index  : 9;
        width    : 30vw;
        height   : 100vh;

        @media ($phone) {
            order  : 2;
            width  : 100vw;
            height : 50vh;
        }

        .login-form-wrap {
            position      : absolute;
            top           : 45%;
            left          : 11vw;
            transform     : translateY(-50%);
            box-shadow    : 0px 0px 20px rgba(0, 53, 84, 0.1);
            border-radius : 8px;
            width         : 455px;
            padding       : 60px 60px 40px 60px;
            background    : #ffffff;

            @media ($desktop-1366) {
                top : 50%;
            }

            @media ($phone) {
                top       : 0;
                left      : 16px;
                right     : 16px;
                transform : translateY(-47%);
                width     : calc(100% - 32px);
                padding   : 30px 16px 24px 16px;
            }

            .logo {
                display       : inline-block;
                margin-bottom : 60px;
                width         : 106px;

                @media($phone) {
                    position  : absolute;
                    top       : -12vh;
                    left      : 50%;
                    transform : translateX(-50%);
                }

                svg {
                    width : 100%;
                }

                path {
                    fill : $blue-100;

                    @media($phone) {
                        fill : #ffffff;
                    }
                }
            }

            h1 {
                margin      : 0 0 27px;
                width       : 100%;
                font-family : $primary-font;
                font-size   : 40px;
                font-weight : 500;
                line-height : 110%;
                color       : $blue-100;
                @media($phone) {
                    margin    : 0 0 22px;
                    font-size : 30px;
                }
            }

            .login-form {
                width : 100%;

                p {
                    margin-bottom : 56px;
                    width         : 100%;
                    font-size     : 18px;
                    font-weight   : 300;
                    line-height   : 21px;
                    color         : $blue-100;

                    @media($phone) {
                        margin-bottom : 30px;
                    }
                }

                label {
                    display       : inline-block;
                    margin-bottom : 8px;
                    width         : 100%;
                    font-size     : 16px;
                    font-weight   : 500;
                    line-height   : 19px;
                    color         : $blue-100;
                }

                button {
                    border           : 0;
                    border-radius    : 54px;
                    width            : 100%;
                    height           : 54px;
                    background-color : $green;
                    cursor           : pointer;
                    font-family      : $primary-font;
                    font-size        : 17px;
                    font-weight      : 500;
                    line-height      : 20px;
                    text-align       : center;
                    color            : #ffffff;

                    &:disabled {
                        opacity : 0.5;
                    }
                }

                .pw-reset {
                    display         : block;
                    margin-top      : 27px;
                    width           : 100%;
                    font-family     : $primary-font;
                    font-size       : 15px;
                    font-weight     : 500;
                    line-height     : 18px;
                    text-align      : center;
                    color           : #797979;
                    text-decoration : none;
                    transition      : color ease-in-out 0.2s;
                    cursor          : pointer;

                    &:hover {
                        color : $blue-100;
                    }
                }

                .form-group {
                    margin-bottom : 25px;
                }

                input {
                    display          : inline-block;
                    box-shadow       : none;
                    border           : 1px solid $blue-100;
                    border-radius    : 4px;
                    outline          : none;
                    box-sizing       : border-box;
                    width            : 100%;
                    height           : 48px;
                    padding          : 16px;
                    background-color : #ffffff;
                    font-family      : $primary-font;
                    font-size        : 16px;
                    font-weight      : 400;
                    line-height      : normal;
                    color            : $blue-100;

                    @media($phone) {
                        padding : 16px;
                    }

                    &::placeholder {
                        opacity     : 0.6;
                        font-family : $primary-font;
                        font-size   : 16px;
                        line-height : normal;
                        font-weight : 400;
                    }

                    &:focus {
                        box-shadow : 0 4px 12px 0 rgba(0, 0, 0, 0.05);
                    }
                }
            }

            .input-error-message {
                color : red;
            }
        }
    }

    .login-side {
        width           : 75vw;
        height          : 100vh;
        background      : url("../../assets/images/login-background.jpg");
        background-size : cover;

        @media ($phone) {
            order  : 1;
            width  : 100vw;
            height : 50vh;
        }
    }
}

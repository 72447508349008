@import "@/assets/sass/global.scss";





































































































































































































@import "../../../../assets/sass/variables";

.modal-header {
    display         : flex;
    flex-direction  : row;
    justify-content : space-between;

    svg {
        opacity    : 0.2;
        transition : opacity ease-out 0.2s;
        cursor     : pointer;
        height     : 48px;
        width      : 48px;

        &:hover {
            opacity : 1;
        }
    }
}

.form {
    min-width : 50vw;

    .materials {
        .material {
            display        : flex;
            flex-direction : row;
        }
    }

    .button {
        padding-left     : 8px;
        padding-right    : 8px;
        margin-top       : 16px;
        line-height      : 48px;
        background-color : #003554;
        color            : #ffffff;
        border-radius    : 4px;
        font-size        : 20px;
        transition       : all ease-out 0.3s;

        &[disabled] {
            color            : transparentize(#ffffff, 0.2);
            background-color : transparentize(#003554, 0.2);
        }
    }

    .form-group {
        margin-top : 8px;
    }

    input {
        transition : opacity ease-out 0.3s;

        &[disabled] {
            opacity : 0.5;
        }
    }
}

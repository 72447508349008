@import "@/assets/sass/global.scss";






















































































.overlay {
    position   : fixed;
    left       : 0;
    top        : 0;
    background : hsla(0, 0%, 0%, 0.1);
    width      : 100vw;
    height     : 100vh;
    z-index    : 100;

    .modal {
        background    : #ffffff;
        position      : fixed;
        left          : 50%;
        top           : 50%;
        transform     : translate(-50%, -50%);
        width         : 50vw;
        border-radius : 8px;

        .header {
            box-sizing      : border-box;
            padding         : 16px;
            display         : flex;
            justify-content : space-between;
            box-shadow      : 0px 0px 12px rgb(215 225 239 / 50%);
            font-size       : 20px;
            font-weight     : bold;

            .close {
                cursor : pointer;
            }
        }

        .body {
            padding : 24px;

            form {
                .form-group {
                    display         : flex;
                    justify-content : space-between;
                    align-content   : center;
                    margin-bottom   : 8px;
                }

                .btn-container {
                    display : flex;
                    margin  : 24px 0 0 0;

                    .btn {
                        margin : 0 0 0 auto;
                    }
                }
            }
        }
    }
}

@import "@/assets/sass/global.scss";




















































































































































































































































































































































































































































tr {
    border-bottom : 1px solid rgba(0, 0, 0, 0.1);

    td {
        input {
            padding-left : 8px;
        }

        &.actions {
            display        : flex;
            flex-direction : column;
            text-align     : center;
            color          : rgba(204, 0, 0, 0.6);

            .button-wrapper {
                display        : flex;
                flex-direction : row;
                margin-bottom  : 8px;

                .btn-delete {
                    display         : flex;
                    padding         : 0 24px;
                    justify-content : center;
                    width           : 25px;
                    color           : white;
                    background      : #cc0000;
                    font-size       : 20px;
                    margin-right    : 8px;
                    line-height     : 36px;
                }

                .accept-btn {
                    height          : 36px;
                    padding         : 0;
                    border-radius   : 4px;
                    background      : linear-gradient(180deg, #0a85cc 0%, #006ead 100%);
                    box-shadow      : 0 2px 4px 0 rgba(0, 0, 0, 0.2);
                    display         : flex;
                    justify-content : center;
                    align-items     : center;
                    width           : 50%;

                    svg {
                        width  : 21px;
                        height : 16px;
                    }
                }
            }

            div {
                cursor        : pointer;
                margin-bottom : 8px;

                &:hover {
                    text-decoration : underline;
                }
            }
        }

        &.status-info {
            cursor         : pointer;
            padding        : 12px 4px;
            display        : flex;
            flex-direction : row;

            .status {
                position           : relative;
                display            : flex;
                justify-content    : center;
                align-items        : center;
                width              : 40px;
                height             : 40px;
                margin-right       : 16px;
                border-radius      : 100%;

                $statusBlueDarker  : #b0e0e6; // open // wit
                $statusBlueDark    : #cc0000; // missing // rood
                $statusBlueLight   : #00bfff; // check planning // lichtblauw
                $statusGreenDarker : #005a7b; // check administration // donkerblauw
                $statusGreenDark   : #ff8c00; // klaar voor kostenstaat // oranje
                $statusGreen       : #ffc125; // te factureren // geel
                $statusYellow      : #008b00; // gefactureerd // groen

                .tooltip {
                    position         : absolute;
                    bottom           : 100%;
                    margin-bottom    : 8px;

                    display          : inline-block;
                    justify-content  : center;
                    align-items      : center;

                    border-radius    : 4px;
                    background-color : #021e2e;
                    box-shadow       : 0 4px 12px -4px rgba(0, 0, 0, 0.5);
                    padding          : 8px;

                    color            : #ffffff;
                    font-size        : 12px;
                    font-weight      : bold;
                    line-height      : 14px;
                    text-align       : center;
                    white-space      : nowrap;

                    transition       : all ease-in-out 0.1s;
                    transform        : translateY(50%) scale(0.6);
                    opacity          : 0;
                }

                &:hover {
                    .tooltip {
                        transform : translateY(0) scale(1);
                        opacity   : 1;
                    }
                }

                &.bg {
                    &-blue-darker {
                        background-color : $statusBlueDarker;
                    }

                    &-blue-dark {
                        background-color : $statusBlueDark;
                    }

                    &-blue-light {
                        background-color : $statusBlueLight;
                    }

                    &-green-darker {
                        background-color : $statusGreenDarker;
                    }

                    &-green-dark {
                        background-color : $statusGreenDark;
                    }

                    &-green {
                        background-color : $statusGreen;
                    }

                    &-yellow {
                        background-color : $statusYellow;
                    }
                }
            }

            .info {
                display        : flex;
                flex-direction : column;
                font-size      : 14px;
                line-height    : 21px;

                .title {
                    color       : #003554;
                    font-weight : 500;
                }

                .subtitle {
                    font-weight : 400;
                    color       : #000000;
                }
            }
        }

        input {
            height      : 34px;
            background  : #f2f7fa;
            color       : #000000;
            border      : 1px solid rgba(0, 0, 0, 0.18);
            font-size   : 14px;
            line-height : 18px;
        }

        &.rewardable-hours {
            input {
                width : 75px;

                &.break {
                    margin-left : 8px;
                }
            }

            .red {
                color : #b30016;
            }

            .green {
                color : #6dbf13;
            }
        }
    }
}

@import "@/assets/sass/global.scss";























































































































































































































































































































































































































































.app-overlay {
    opacity        : 0;
    position       : fixed;
    top            : 0;
    left           : 0;
    z-index        : 101;
    width          : 100vw;
    height         : 100vh;
    transition     : all ease-out 0.2s;
    pointer-events : none;

    &.active {
        opacity        : 1;
        pointer-events : all;
        cursor         : pointer;
    }
}

#datepicker-main {
    position : relative;
}

.select-box {
    display         : flex;
    flex-direction  : row;
    justify-content : space-between;
    align-items     : center;
    width           : 270px;
    background      : #e4edf2;
    padding-left    : 16px;
    padding-right   : 16px;
    height          : 50px;
    border-radius   : 4px;
    cursor          : pointer;
    transition      : all ease-out 0.2s;

    &.active {
        background : #008dc7;
        color      : #ffffff;
    }

    .arrow {
        content      : '';
        width        : 0;
        height       : 0;
        border-style : solid;
        border-width : 5px 5px 0 5px;
        border-color : #000000 transparent transparent transparent;
        opacity      : 0.6;
    }

    .column {
        display         : flex;
        flex-direction  : column;
        justify-content : center;

        & > div {
            font-family : ".SF NS", sans-serif;
            font-size   : 16px;
            line-height : 16px;
            font-weight : 600;

            &.dates {
                color     : transparentize(white, 0.2);
                font-size : 14px;
            }
        }
    }
}

.overlay {
    border-radius   : 6px;
    overflow        : hidden;
    display         : flex;
    flex-direction  : row;
    justify-content : space-between;
    position        : absolute;
    top             : 50px;
    left            : 0;
    z-index         : 999;
    background      : #ffffff;
    transition      : all ease-out 0.2s;
    opacity         : 0;
    pointer-events  : none;
    box-shadow      : 0 20px 30px -10px rgba(0, 0, 0, 0.5);

    &.active {
        transform      : translateY(8px);
        opacity        : 1;
        pointer-events : all;
    }

    .option-list {
        height          : auto;
        min-height      : 354px;
        display         : flex;
        flex-direction  : column;
        justify-content : space-between;
        position        : relative;
        width           : 210px;
        padding         : 48px 16px;
        box-shadow      : 2px 0 8px 0 rgba(0, 0, 0, 0.05);
        background      : #ffffff;

        & > div {
            font-family   : ".SF NS", sans-serif;
            font-size     : 16px;
            font-weight   : 500;
            color         : #000000;
            padding       : 8px;
            border-radius : 4px;
            transition    : all ease-out 0.2s;
            cursor        : pointer;

            &:hover {
                background : rgba(0, 120, 189, 0.1);
            }
        }
    }

    .calendar-wrapper {
        background : #f2f7fa;
        padding    : 0 16px;

        .title {
            height          : 48px;
            display         : flex;
            flex-direction  : row;
            justify-content : space-between;
            align-items     : center;
            box-shadow      : 0 1px 0 0 rgba(0, 0, 0, 0.05);

            .month {
                font-family : ".SF NS", sans-serif;
                font-size   : 16px;
                line-height : 32px;
                color       : #000000;
                font-weight : 600;
            }

            .buttons {
                button {
                    background    : #ffffff;
                    border        : 1px solid #e4e7e7;
                    border-radius : 4px;
                    padding       : 4px 8px;
                    cursor        : pointer;

                    svg {
                        height : 20px;
                        width  : 20px;
                        fill   : #82888a;
                    }
                }
            }
        }

        .calendar {
            margin-top    : 16px;
            padding-left  : 16px;
            padding-right : 16px;
            font-family   : ".SF NS", sans-serif;

            .header {
                list-style-type : none;
                padding         : 10px 0;
                margin          : 0;
                font-size       : 14px;
                font-weight     : normal;
                color           : rgba(0, 0, 0, 0.3);

                li {
                    list-style-type : none;
                    display         : inline-block;
                    width           : 11%;
                    text-align      : center;
                    margin-bottom   : 5px;

                    &:first-child {
                        margin-right : 16px;
                    }
                }
            }

            .dates-grid {
                list-style-type : none;
                padding         : 10px 0;
                margin          : 0;
                width           : 356px;

                li {
                    padding-top     : 8px;
                    padding-bottom  : 8px;
                    list-style-type : none;
                    display         : inline-block;
                    width           : 11%;
                    text-align      : center;
                    margin-bottom   : 5px;
                    color           : #000000;
                    font-size       : 14px;
                    font-weight     : 500;
                    cursor          : pointer;
                    transition      : all ease-out 0.2s;

                    &.previous-month {
                        opacity : 0.5;
                    }

                    &.week {
                        margin-right : 16px;
                        color        : rgba(0, 0, 0, 0.3);

                        &:hover {
                            background : unset;
                        }
                    }

                    &.between {
                        opacity    : 1;
                        background : rgba(0, 120, 189, 0.2);
                    }

                    &.hoveringWeek {
                        background : rgba(0, 120, 189, 0.2);
                    }

                    &.today {
                        background : rgba(0, 20, 255, 0.2);
                    }

                    &.first {
                        opacity                   : 1;
                        background                : #008dc7;
                        border-top-left-radius    : 4px;
                        border-bottom-left-radius : 4px;
                        color                     : #ffffff;
                    }

                    &.second {
                        opacity                    : 1;
                        background                 : #008dc7;
                        border-top-right-radius    : 4px;
                        border-bottom-right-radius : 4px;
                        color                      : #ffffff;
                    }

                    &.disabled {
                        pointer-events : none;

                        &:hover {
                            background : unset;
                            cursor     : none;
                        }
                    }

                    &:after {
                        content : "";
                        display : block;
                    }

                    &:hover {
                        background : rgba(0, 120, 189, 0.2);
                    }
                }
            }
        }
    }
}

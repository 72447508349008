@import "@/assets/sass/global.scss";




















































































































































































































































































































































































































































































































































































@import '../assets/sass/_variables.scss';
@import '../assets/sass/media-queries.scss';

#sidebar {
    position         : fixed;
    z-index          : 100;
    overflow-x       : visible;
    overflow-y       : auto;
    border-right     : 1px solid #F2F2F2;
    width            : 300px;
    height           : 100vh;
    padding-bottom   : 120px;
    background-color : #FFFFFF;
    transition       : all ease-in 0.3s;

    .logo {
        padding : 20px 0 64px 30px;

        path {
            fill : $blue-100 !important;
        }
    }

    .menu,
    .bottom {
        > ul {
            > li {
                margin-bottom : 16px;

                > a {
                    position        : relative;
                    display         : flex;
                    align-items     : center;
                    justify-content : space-between;
                    padding         : 8px 24px;
                    cursor          : pointer;
                    text-decoration : none;

                    .menu-item-label {
                        display     : flex;
                        align-items : center;

                        .menu-icon {
                            margin-right : 16px;
                            width        : 24px;

                            svg {
                                vertical-align : middle;

                                path {
                                    transition : all 0.2s ease-out;
                                    fill       : $blue-100;
                                }
                            }
                        }

                        span {
                            font-size   : 17px;
                            font-weight : 500;
                            line-height : 24px;
                            color       : $blue-100;
                            transition  : all 0.2s ease-out;
                        }
                    }

                    .arrow {
                        display         : flex;
                        align-items     : center;
                        justify-content : center;
                        height          : 32px;
                        pointer-events  : none;

                        svg {
                            opacity    : 0.6;
                            transform  : rotateZ(180deg);
                            width      : 16px;
                            transition : all ease-in-out 0.3s;

                            fill       : #FFFFFF;
                        }
                    }
                }

                &:hover, &.active {
                    position : relative;

                    > a {
                        background-color : rgba(255, 255, 255, 0.1);

                        .menu-item-label {
                            .menu-icon {
                                svg {
                                    vertical-align : middle;

                                    path {
                                        fill : $blue-bright;
                                    }
                                }
                            }

                            span {
                                color : $blue-bright;
                            }
                        }

                        &:before {
                            content          : '';
                            position         : absolute;
                            top              : 10px;
                            bottom           : 10px;
                            right            : 0;
                            width            : 3px;
                            background-color : $blue-bright;

                            @media ($tablet-portrait) {
                                display : none;
                            }
                        }
                    }
                }
            }

            li.active {
                ul.sub-menu {
                    display : block;
                }
            }
        }

        .sub-menu {
            display : none;
            padding : 16px 16px 16px 64px;

            li {
                margin-top : 16px;
                list-style : none;

                &:first-child {
                    margin-top : 0;
                }

                .time-tracking-count {
                    margin-right  : 0;
                    margin-left   : auto;
                    border        : 0;
                    border-radius : 16px;
                    padding       : 0 16px;
                    background    : #F1F5FB;
                    font-size     : 12px;
                }

                & > a {
                    display         : flex;
                    align-items     : center;
                    justify-content : space-between;
                    cursor          : pointer;
                    font-size       : 15px;
                    font-weight     : 300;
                    line-height     : 24px;
                    text-decoration : none;
                    color           : $blue-100;
                    transition      : all 0.2s ease-out;

                    &.active {
                        color : $blue-bright;
                    }
                }

                &:hover {
                    > a {
                        color : $blue-bright;
                    }
                }

                &.active {
                    > a {
                        font-weight : 500;
                        color       : $blue-100;
                    }
                }
            }
        }
    }

    .menu {
        .indicator {
            display          : inline-block;
            margin-left      : 8px;
            border-radius    : 9px;
            min-width        : 18px;
            height           : 18px;
            padding          : 0 4px;
            background-color : rgba(255, 255, 255, 0.2);
            font-size        : 12px;
            font-weight      : 500;
            line-height      : 18px;
            text-align       : center;
            color            : #FFFFFF;
        }
    }

    .bottom {
        position   : fixed;
        bottom     : 0;
        border-top : 1px solid #F2F2F2;
        width      : 300px;

        ul {
            opacity    : 1;
            background : #FFFFFF;

            li {
                margin-bottom : 0;

                &:hover, &.active {
                    a {
                        .menu-item-label {
                            .menu-icon {
                                svg {
                                    vertical-align : middle;

                                    path {
                                        fill : $blue-bright;
                                    }
                                }
                            }

                            span {
                                color : $blue-bright;
                            }
                        }

                        &:before {
                            display : none;
                        }
                    }
                }
            }

            li.active {
                ul.sub-menu {
                    display : block;
                }
            }
        }
    }

    &.sidebar--collapsed {
        overflow : visible;
        width    : 70px;

        @media ($phone) {
            display : none;
        }

        .logo {
            overflow : hidden;
            margin   : 0;
            width    : 100%;
            padding  : 20px 8px 64px 8px;

            svg {
                width : 100%;
            }
        }

        &.sidebar--collapsed--toggling {
            .menu .sub-menu {
                transition : none !important;
            }

            .profile {
                transition : none !important;
            }
        }

        .menu,
        .bottom {
            ul {
                li {
                    a {
                        .menu-item-label {
                            display : flex;

                            span {
                                display : none;
                            }
                        }

                        .arrow {
                            display : none;
                        }
                    }

                    &:hover, &.active {
                        a {
                            &:before {
                                display : none;
                            }
                        }
                    }
                }
            }
        }

        .menu {
            ul {
                li {
                    .sub-menu {
                        position         : absolute;
                        left             : 100%;
                        display          : block;
                        opacity          : 0;
                        transform        : translateY(-16px);
                        margin-top       : -32px;
                        margin-left      : -4px;
                        box-shadow       : 0 8px 24px -4px rgba(0, 0, 0, 0.25);
                        border           : 1px solid rgba(0, 0, 0, 0.02);
                        border-radius    : 8px;
                        width            : 228px;
                        padding          : 24px 16px;
                        background-color : #FFFFFF;
                        transition       : all ease-in-out 0.2s;
                        visibility       : hidden;

                        &.management {
                            transform : translateY(-112px);
                        }
                    }

                    &.active {
                        margin-bottom : 16px;
                    }

                    &:hover {
                        .sub-menu {
                            opacity    : 1;
                            transform  : translateY(0);
                            visibility : visible;

                            &.management {
                                transform : translateY(-128px);
                            }
                        }
                    }
                }

                li.active {
                    ul.sub-menu {
                        display : block;
                    }
                }
            }

            .indicator {
                background-color : $blue-20;
                color            : $blue-100;
            }
        }

        .bottom {
            width       : 70px;
            padding-top : 8px;
        }
    }
}

#nav {
    background-color : $blue-100;

    .hamburger {
        z-index          : 99999999;
        display          : flex;
        align-items      : center;
        justify-content  : center;
        width            : 55px;
        background-color : #002840;
        cursor           : pointer;

        svg {
            width : 20px;

            path {
                fill : #FFFFFF;
            }
        }
    }

    .menu {
        ul {
            display     : flex;
            align-items : flex-end;

            li {
                opacity       : 0.5;
                margin-left   : 16px;
                border-bottom : 4px solid transparent;

                a {
                    display        : flex;
                    align-items    : center;
                    padding        : 20px 16px;

                    font-size      : 14px;
                    font-weight    : 600;
                    line-height    : 22px;
                    letter-spacing : 0.16px;
                    color          : #FFFFFF;

                    svg {
                        margin-right : 10px;
                    }
                }

                &.active {
                    opacity             : 1;
                    border-bottom-color : $blue-bright;
                }
            }
        }
    }
}

@import "@/assets/sass/global.scss";


















































@import "../assets/sass/_variables.scss";
@import "../assets/sass/media-queries.scss";

.person-wrap {
    width         : 100%;
    border-bottom : 1px solid rgba(0, 53, 84, 0.1);
    padding       : 16px 20px;
    font-weight   : 300;
    font-size     : 18px;
    line-height   : 21px;
    display       : inline-flex;
    align-items   : center;
    position      : relative;
    cursor        : pointer;

    &:after {
        content         : '';
        background      : url("/images/icons/arrow-blue.svg") no-repeat;
        background-size : 100%;
        position        : absolute;
        width           : 10px;
        right           : 16px;
        height          : 16px;
        opacity         : 0.25;
    }

    &.is-active {
        background : $blue-20;

        &:after {
            opacity : 1;
        }
    }

    img {
        margin-right  : 20px;
        border-radius : 100%;
        width         : 48px;
        height        : 48px;
        object-fit    : cover;
    }
}

.left {
    z-index       : 8;
    width         : 50%;
    background    : #ffffff;
    border        : 1px solid #f2f2f2;
    box-shadow    : 0px 0px 12px #f1f5fb;
    border-radius : 8px 0px 0px 8px;
    align-self    : flex-start;
    @media($phone) {
        width         : 100%;
        box-shadow    : none;
        border-radius : 0;
        border-left   : 0;
        border-right  : 0;
    }

    > div {
        &:last-child {
            .person-wrap {
                border-bottom : 0px;
            }
        }
    }

    a {
        text-decoration : none;
        color           : inherit;
    }
}

.right {
    z-index : 7;
    width   : 50%;

    @media ($phone) {
        &:not(.active) {
            display : none;
        }
    }
}

@import "@/assets/sass/global.scss";












































































































































@import "../../assets/sass/_variables.scss";
@import "../../assets/sass/media-queries.scss";

.button-wrap {
    display                    : flex;
    overflow-x                 : auto;
    margin-bottom              : 5px;
    margin-left                : -16px;
    width                      : calc(100% + 32px);
    padding-bottom             : 15px;
    -webkit-overflow-scrolling : touch;

    button {
        position      : relative;
        border        : 0;
        border-radius : 16px;
        outline       : 0;
        height        : 32px;
        padding       : 0px 16px;
        background    : $blue-20;
        font-size     : 13px;
        font-weight   : 500;
        line-height   : 15px;
        transition    : all 0.3s ease-in;

        &:hover, &.active {
            background : $blue-bright;
            color      : #ffffff;
        }

        &.mrgn-right {
            margin-left : 8px;
        }

        &:first-of-type {
            margin-left : 16px;
        }

        &:last-child {
            &:after {
                content  : "";
                position : absolute;
                right    : -16px;
                display  : block;
                width    : 16px;
                height   : 1px;
            }
        }
    }
}

.option-grid {
    display               : grid;
    margin-bottom         : 16px;
    grid-template-columns : 1fr 1fr 1fr 1fr;
    gap                   : 20px;

    @media($phone) {
        grid-template-columns : 1fr 1fr;
    }

    a {
        text-decoration : none;
        color           : $blue-100;
    }

    .box {
        display         : flex;
        align-content   : space-between;
        align-items     : center;
        flex-direction  : column;
        justify-content : space-between;
        box-shadow      : 0px 0px 12px rgba(215, 225, 239, 0.5);
        border          : 1px solid #f2f2f2;
        border-radius   : 16px;
        width           : 100%;
        height          : 200px;
        padding         : 40px 20px;
        font-size       : 15px;
        font-weight     : 500;
        line-height     : 18px;
        text-align      : center;

        .option-name {
            display : block;
            width   : 100%;
            hyphens : auto;
        }

        img {
            display : block;

            &.icon-car {
                width : 45px;
            }

            &.icon-time {
                width : 61px;
            }

            &.icon-suitcase {
                width : 44px;
            }

            &.icon-contact {
                width : 38px;
            }
        }
    }
}

.small-inner-box {
    position        : relative;
    display         : block;
    margin-bottom   : 16px;
    box-shadow      : 0px 0px 12px rgba(215, 225, 239, 0.5);
    border          : 1px solid #f2f2f2;
    border-radius   : 16px;
    max-width       : 440px;
    padding         : 16px;
    text-decoration : none;
    color           : inherit;

    &.loading-container {
        display         : flex;
        justify-content : center;
    }

    &:after {
        content         : '';
        position        : absolute;
        top             : 50%;
        right           : 16px;
        transform       : translateY(-60%);
        width           : 10px;
        height          : 16px;
        background      : url("../../../public/images/icons/arrow-blue.svg");
        background-size : 100%;
    }

    @media($phone) {
        max-width : 100%;
    }

    .order-number {
        display       : block;
        margin-bottom : 5px;
        font-size     : 14px;
        font-weight   : 300;
        line-height   : 17px;
        color         : rgba(0, 53, 84, 0.6);
    }

    .order-name {
        display       : block;
        margin-bottom : 5px;
        font-size     : 15px;
        font-weight   : 500;
        line-height   : 18px;
        color         : $blue-100;
    }

    .order-details {
        display     : block;
        font-size   : 14px;
        font-weight : 300;
        line-height : 17px;
    }
}


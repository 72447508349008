@import "@/assets/sass/global.scss";


























































@import "../assets/sass/media-queries.scss";

.manual-wrapper {
    display        : flex;
    flex-direction : row;
    padding        : 16px 20px;
    border-bottom  : 1px solid rgba(0, 53, 84, 0.1);
    cursor         : pointer;

    &:first-of-type {
        border-top : 1px solid rgba(0, 53, 84, 0.1);
    }

    .delete-icon {
        border      : none;
        margin-left : 16px;
        background  : transparent;
        width       : 14px;
        height      : 14px;

        svg {
            path {
                fill : #003554;
            }
        }
    }

    a {
        text-decoration : none;
        color           : #003554;

        &:visited {
            color : #003554;
        }
    }
}

.pointer {
    cursor : pointer;
}

@import "@/assets/sass/global.scss";






































































































@import "../../../assets/sass/_variables.scss";
@import "../../../assets/sass/media-queries.scss";

.loading-wrapper {
    display         : flex;
    justify-content : center;
    margin-bottom   : 24px;
}

.month {
    width         : 160px;
    margin-bottom : 20px;
}

.no-commuting-available {
    width         : 100%;
    display       : inline-block;
    text-align    : center;
    font-weight   : 300;
    font-size     : 18px;
    line-height   : 25px;
    margin-bottom : 20px;
}

h1 {
    @media($phone) {
        margin-bottom : 24px;
    }
}

.box-wrap {
    @media($phone) {
        margin-top : 16px;
    }
}

.commuting-item {
    margin-bottom : 20px;
    position      : relative;

    .commuting-inner {
        background    : #ffffff;
        border        : 1px solid #f2f2f2;
        box-sizing    : border-box;
        box-shadow    : 0px 0px 12px #f1f5fb;
        border-radius : 8px;
        padding       : 22px 24px;
        position      : relative;
    }

    span {
        width   : 100%;
        display : inline-block;

        &.commuting-date {
            font-weight   : 300;
            font-size     : 14px;
            line-height   : 17px;
            margin-bottom : 5px;
        }

        &.commuting-distance {
            font-weight : 500;
            font-size   : 16px;
            line-height : 19px;
        }
    }
}

@import "@/assets/sass/global.scss";

















































































































































































































































































































































































































































* {
    position : relative;
}

input {
    position : relative !important;
    left     : 0 !important;
}

.main {
    background : #ffffff;
}

.filters {
    position        : fixed;
    top             : 59px;
    left            : 280px;
    background      : #ffffff;
    z-index         : 9;
    width           : calc(100% - 280px);
    padding         : 8px 16px 8px 48px;
    display         : flex;
    flex-direction  : row;
    justify-content : flex-start;
    align-items     : center;
    box-shadow      : 0 8px 32px rgba(0, 0, 0, 0.5);

    .search-input {
        background    : #e4edf2;
        height        : 50px;
        width         : 300px;
        border-radius : 4px;
        font-size     : 16px;
        font-weight   : 600;
        padding-right : 8px;
        padding-left  : 8px;
        border        : 1px solid transparent;
        transition    : all ease-out 0.2s;

        &:focus {
            border : 1px solid #008ec7;
        }

        &.active {
            background : linear-gradient(180deg, #0a85cc 0%, #006ead 100%);
            color      : #ffffff;
        }
    }

    & > * {
        margin-right : 16px;

        &:last-child {
            margin-right : 0;
        }
    }


    @media (max-width : 992px) {
        left  : 0;
        width : 100%;
    }

    .search-button {
        font-family   : ".SF NS", sans-serif;
        cursor        : pointer;
        height        : 50px;
        width         : 120px;
        background    : #003554;
        color         : #ffffff;
        border-radius : 4px;
        font-size     : 16px;
        font-weight   : 600;
        border        : none;
        transition    : all ease-out 0.2s;

        &:hover {
            background : #004c78;
        }
    }

    .reset-filters {
        cursor : pointer;
    }
}

table {
    background : #ffffff;
}

.content-header {
    display               : grid;
    grid-template-columns : auto auto;
    margin-top            : 0;
    padding-top           : 48px;
    padding-left          : 48px;

    .title {
        display     : flex;
        align-items : center;
        font-family : ".SF NS", sans-serif;
        color       : #021e2e;
        font-weight : bold;
        font-size   : 36px;
        line-height : 36px;
    }

    .buttons {
        display         : flex;
        align-items     : center;
        justify-content : flex-end;

        button {
            font-family   : ".SF NS", sans-serif;
            cursor        : pointer;
            height        : 55px;
            width         : 170px;
            background    : #003554;
            color         : #ffffff;
            border-radius : 4px;
            font-size     : 16px;
            line-height   : 18px;
            font-weight   : 600;
            border        : none;
            margin-right  : 16px;
            transition    : all ease-out 0.2s;

            &:hover {
                background : lighten(#003554, 7%);
            }
        }
    }
}

.content {
    position      : relative;
    width         : 100%;
    margin        : 32px 0 64px;
    padding-left  : 48px;
    padding-right : 48px;

    .project-table {
        width : 100%;

        thead {

            tr {
                box-shadow : 0px 1px 0px rgba(0, 0, 0, 0.1);

                th {
                    color          : #000000;
                    font-size      : 16px;
                    line-height    : 18px;
                    font-weight    : bold;
                    padding        : 12px 16px;
                    font-family    : ".SF NS", sans-serif;
                    mix-blend-mode : normal;
                    text-align     : left;

                    &.date {
                        width : 110px;
                    }

                    &.cost-state-status-header {
                        min-width : 190px;
                    }

                    .table-sort {
                        margin-left : 4px;
                        display     : inline-block;
                        width       : 10px;
                        height      : 10px;

                        &::before {
                            top          : -2px;
                            border-width : 0 5px 5px;
                            border-color : transparent transparent #000000;
                            display      : block;
                            position     : absolute;
                            width        : 0;
                            height       : 0;
                            transition   : opacity 0.2s;
                            border-style : solid;
                            content      : '';
                            opacity      : 0.5;
                        }

                        &::after {
                            bottom       : -2px;
                            border-width : 5px 5px 0;
                            border-color : #000000 transparent transparent;
                            display      : block;
                            position     : absolute;
                            width        : 0;
                            height       : 0;
                            transition   : opacity 0.2s;
                            border-style : solid;
                            content      : '';
                            opacity      : 0.5;
                        }
                    }
                }
            }
        }

        tbody {
            tr {
                cursor     : pointer;
                box-shadow : 0px 1px 0px rgba(0, 0, 0, 0.1);

                td {
                    padding     : 22px 16px;
                    font-family : ".SF NS", sans-serif;
                    font-size   : 14px;
                    line-height : 18px;
                    color       : #000000;

                    .cost-state {
                        cursor        : pointer;
                        padding       : 8px 16px;
                        background    : #ebeff5;
                        border-radius : 16px;

                        font-weight   : 500;
                        font-size     : 13px;
                        line-height   : 15px;
                        color         : #003554;
                        transition    : all ease-out 0.2s;

                        &:hover {
                            background : darken(#ebeff5, 5%);
                        }
                    }
                }
            }
        }
    }
}


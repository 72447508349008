@import "@/assets/sass/global.scss";











































































































@import "../../assets/sass/_variables.scss";
@import "../../assets/sass/media-queries.scss";

h1 {
    @media ($phone) {
        display : none;
        left    : 20px;
    }
}

.back-mobile {
    display : none;

    @media($phone) {
        display     : block;
        margin-left : 35px;
    }
}

.account-wrapper {
    width     : 100%;
    max-width : 700px;

    @media ($phone) {
        width       : calc(100% + 40px);
        margin-left : -20px;
        margin-top  : -20px;
    }

    .buttons-wrapper {
        display        : flex;
        flex-direction : row;

        .btn-wrap {
            margin-top   : 36px;
            margin-right : 16px;

            @media ($phone) {
                padding : 0 20px;
            }

            button {
                background    : #5bb6a1;
                border-radius : 27px;
                font-weight   : 500;
                color         : #ffffff;
                font-size     : 14px;
                line-height   : 17px;
                height        : 40px;
                padding       : 0px 30px;
                border        : 0;

                a {
                    text-decoration : none;

                    &:visited {
                        color : #ffffff;
                    }
                }
            }
        }
    }

    .picture-wrap {
        display        : flex;
        align-items    : center;
        padding-left   : 20px;
        padding-bottom : 38px;
        padding-top    : 30px;
        @media ($phone) {
            flex-direction  : column;
            justify-content : center;
            padding-left    : 0;
            background      : $blue-20;
        }

        img {
            width         : 100px;
            min-width     : 100px;
            height        : 100px;
            border-radius : 100%;
            object-fit    : cover;
        }

        .main-info {
            width       : 100%;
            margin-left : 28px;

            @media ($phone) {
                margin-top  : 20px;
                text-align  : center;
                margin-left : 0;
            }

            .name {
                width       : 100%;
                display     : inline-block;
                font-weight : 500;
                font-size   : 30px;
                line-height : 36px;
            }

            .function {
                font-weight : 300;
                font-size   : 20px;
                line-height : 24px;
            }
        }
    }

    .personaldetails {
        width   : 100%;
        display : inline-block;

        @media ($phone) {
            padding : 20px 20px 0px 20px;
        }

        .detail-row {
            width         : 100%;
            padding       : 16px 20px;
            border-bottom : 1px solid rgba(0, 53, 84, 0.1);

            @media ($phone) {
                padding : 16px 0px;
            }

            .label {
                width       : 100%;
                font-weight : 500;
                display     : inline-block;
                font-size   : 16px;
                line-height : 26px;
            }

            .info {
                font-weight : 300;
                width       : 100%;
                display     : inline-block;
                font-size   : 16px;
                line-height : 26px;
            }
        }
    }

    .manuals {
        margin-top     : 32px;
        display        : flex;
        flex-direction : column;

        .loading-wrapper {
            align-self : center;
        }
    }
}

// fonts
$primary-font      : 'Rubik', sans-serif;

// new colors
$blue-20           : #F1F5FB;
$blue-100          : #003554;
$blue-bright       : #00B0DE;
$white             : #FFFFFF;
$grey              : #D8D8D8;
$green             : #5BB6A1;

// old (preserve for replacement)
$statusBlueDarker  : #B0E0E6; // open // wit
$statusBlueDark    : #cc0000; // missing // rood
$statusBlueLight   : #00BFFF; // check planning // lichtblauw
$statusGreenDarker : #005A7B; // check administration // donkerblauw
$statusGreenDark   : #ff8c00; // klaar voor kostenstaat // oranje
$statusGreen       : #ffc125; // te factureren // geel
$statusYellow      : #008b00; // gefactureerd // groen